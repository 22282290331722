import React, { useEffect, useRef, useState } from 'react';
const resetSymbol = Symbol('reset');
export const createContext = (Store, { init, dispose } = {}) => {
    const context = React.createContext(null);
    const Provider = (props) => {
        const { children } = props;
        const staticStore = 'staticStore' in props ? props.staticStore : null;
        const args = 'arguments' in props ? props.arguments : [];
        const firstArgsBypass = useRef(true);
        const [store, setStore] = useState(staticStore || (() => new Store(...args)));
        useEffect(() => {
            init?.(store);
            return () => dispose?.(store);
        }, [store]);
        useEffect(() => {
            if (staticStore)
                return;
            if (firstArgsBypass.current) {
                firstArgsBypass.current = false;
                return;
            }
            setStore(new Store(...args));
        }, args);
        store[resetSymbol] = () => {
            if (staticStore)
                throw new Error('Cannot reset static store');
            setStore(new Store(...args));
        };
        return React.createElement(context.Provider, { value: store }, children);
    };
    const useStore = () => {
        const store = React.useContext(context);
        if (!store) {
            throw new Error(`No context found for ${Store.prototype.constructor.name}`);
        }
        return store;
    };
    return { Provider, useStore };
};
export const resetStore = (store) => {
    if (resetSymbol in store) {
        store[resetSymbol]();
    }
    else {
        throw new Error('Store does not have reset method');
    }
};
