import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import { ModelListPage } from "./pages/ModelList/ModelList";
import { CreateNewModelPage } from "./pages/CreateNewModel/CreateNewModel";
import { MainPage } from "./pages/Main/Main";
import { LoginPage } from "./pages/Login/Login";
import { ModelPage } from "./pages/Model/Model";
import { useEffect } from "react";
import { userStore } from "./stores/LoginStore";
import { observer } from "mobx-react-lite";
const MustBeLoggedIn = observer(function MustBeLoggedIn({ children, redirectOtherwise = '/login' }) {
    const nav = useNavigate();
    useEffect(() => {
        if (!userStore.isLoggerIn) {
            nav(redirectOtherwise);
        }
    }, [userStore.isLoggerIn]);
    return _jsx(_Fragment, { children: children });
});
const MustNotBeLoggedIn = observer(function MustNotBeLoggedIn({ children, redirectOtherwise = '/' }) {
    const nav = useNavigate();
    useEffect(() => {
        if (userStore.isLoggerIn) {
            nav(redirectOtherwise);
        }
    }, [userStore.isLoggerIn]);
    return _jsx(_Fragment, { children: children });
});
export const router = createBrowserRouter([
    {
        path: "/",
        element: _jsx(MainPage, {}),
        children: [
            {
                index: true,
                element: _jsx(ModelListPage, {})
            },
            {
                path: '/login',
                element: _jsx(MustNotBeLoggedIn, { children: _jsx(LoginPage, {}) })
            },
            {
                path: '/model/:id',
                element: _jsx(ModelPage, {})
            },
            {
                path: '/create',
                element: _jsx(MustBeLoggedIn, { children: _jsx(CreateNewModelPage, {}) })
            }
        ]
    }
]);
