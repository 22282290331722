import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { useModel } from "../../../stores/model";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, Slider, TextField, Typography } from "@mui/material";
import { useScadDocument } from "../../../stores/ScadDocumentDataFrame";
import css from './ScadParams.module.scss';
import { useEffect, useState } from "react";
import { assert } from "../../../util/assert";
import classNames from "classnames";
const SectionExpression = observer((props) => {
    const model = useModel();
    const { param } = props;
    return (_jsx(TextField, { className: css.inputField, label: param.prettyName, value: model.params.vars[param.varName] ?? param.value, onChange: (ev) => {
            const value = ev.target.value;
            model.setVar(param.varName, value);
        }, variant: "standard" }));
});
const SectionBoolean = observer((props) => {
    const model = useModel();
    const { param } = props;
    const labelId = `boolean-label-${param.varName}`;
    return (_jsxs(FormControl, { variant: "standard", style: { marginBottom: '1rem', marginRight: '1em' }, children: [_jsx(InputLabel, { id: labelId, children: param.prettyName }), _jsxs(Select, { labelId: labelId, className: css.inputField, label: param.prettyName, value: (model.params.vars[param.varName] ?? param.value) ? 1 : 0, onChange: (ev) => {
                    const value = ev.target.value;
                    model.setVar(param.varName, !!value);
                }, variant: "standard", children: [_jsx(MenuItem, { value: 1, children: "True / On" }), _jsx(MenuItem, { value: 0, children: "False / Off" })] })] }));
});
const SectionString = observer((props) => {
    const model = useModel();
    const { param } = props;
    return (_jsx(TextField, { className: css.inputField, label: param.prettyName, value: model.params.vars[param.varName] ?? param.value, onChange: (ev) => {
            const value = ev.target.value;
            model.setVar(param.varName, value);
        }, variant: "standard" }));
});
const SectionVector = observer((props) => {
    const model = useModel();
    const { param } = props;
    const [localValue, setLocalValue] = useState(JSON.stringify(param.value));
    const [error, setError] = useState('');
    useEffect(() => {
        setLocalValue(JSON.stringify(model.params.vars[param.varName] ?? param.value));
    }, [model.params.vars[param.varName] ?? param.value]);
    return (_jsx(TextField, { className: css.inputField, label: param.prettyName, value: localValue, onChange: (ev) => {
            const value = ev.target.value;
            setLocalValue(value);
            try {
                const val = JSON.parse(value);
                if (!Array.isArray(val)) {
                    throw new Error('Value must be an array');
                }
                for (const value of val) {
                    if (typeof value !== 'number') {
                        throw new Error('Invalid value');
                    }
                }
                model.setVar(param.varName, val);
                setError('');
            }
            catch (e) {
                console.error('SectionVector', e);
                setError(e.toString());
            }
        }, error: !!error, helperText: error, variant: "standard" }));
});
const SectionNumber = observer((props) => {
    const model = useModel();
    const { param } = props;
    if (param.choices?.length) {
        console.error('numeric choices not implemented');
    }
    if (param.range &&
        typeof param.range.min === 'number' &&
        typeof param.range.max === 'number') {
        const labelId = `slider-label-${param.varName}`;
        // we use a slider instead
        return (_jsxs("div", { className: css.sliderVariantContainer, children: [_jsx(Typography, { id: labelId, children: param.prettyName }), _jsxs("div", { className: classNames(css.inputField, css.sliderVariant), children: [_jsx(Slider, { className: css.slider, min: param.range.min, max: param.range.max, step: param.range.step, marks: !!param.range.step, value: model.params.vars[param.varName] ?? param.value, onChange: (ev, value) => {
                                model.setVar(param.varName, value);
                            } }), _jsx(TextField, { className: css.input, value: model.params.vars[param.varName] ?? param.value, onChange: (ev) => {
                                const value = parseFloat(ev.target.value);
                                model.setVar(param.varName, value);
                            }, inputProps: {
                                type: 'number',
                                step: param.range.step,
                                min: param.range.min,
                                max: param.range.max,
                            }, variant: "outlined" })] })] }));
    }
    return (_jsx(TextField, { className: css.inputField, label: param.prettyName, value: model.params.vars[param.varName] ?? param.value, onChange: (ev) => {
            const value = parseFloat(ev.target.value);
            model.setVar(param.varName, value);
        }, inputProps: {
            type: 'number',
            step: param.range?.step,
            min: param.range?.min,
            max: param.range?.max,
        }, variant: "standard" }));
});
const SectionChoice = observer((props) => {
    const model = useModel();
    const { param } = props;
    const choices = param.choices;
    assert(choices && choices.length > 0, 'choices must be defined and have length > 0');
    const currentValue = model.params.vars[param.varName] ?? param.value;
    const currentIndex = choices.findIndex(c => JSON.stringify(c.val) === JSON.stringify(currentValue));
    const labelId = `choice-label-${param.varName}`;
    return (_jsxs(FormControl, { variant: "standard", style: { marginBottom: '1rem', marginRight: '1em' }, children: [_jsx(InputLabel, { id: labelId, children: param.prettyName }), _jsx(Select, { labelId: labelId, className: css.inputField, label: param.prettyName, variant: "standard", onChange: (ev) => {
                    const idx = ev.target.value;
                    const choice = choices[idx];
                    model.setVar(param.varName, choice.val);
                }, value: currentIndex, children: choices?.map((choice, idx) => (_jsx(MenuItem, { value: idx, children: choice.desc || JSON.stringify(choice.val) }, idx))) })] }));
});
export const ScadParams = observer((props) => {
    const document = useScadDocument();
    // todo move to suspend
    if (!document.data) {
        return _jsx("div", { children: "Loading..." });
    }
    const params = document.data.variables;
    return _jsx("div", { className: props.className, children: params.map((section, idx) => (_jsxs(Accordion, { defaultExpanded: true, children: [_jsx(AccordionSummary, { children: section.sectionName }), _jsx(AccordionDetails, { children: section.variables.map((param) => {
                        if ((param.choices?.length ?? 0) > 0) {
                            return _jsx(SectionChoice, { param: param }, param.varName);
                        }
                        if (param.type === 'number') {
                            return _jsx(SectionNumber, { param: param }, param.varName);
                        }
                        if (param.type === 'vector') {
                            return _jsx(SectionVector, { param: param }, param.varName);
                        }
                        if (param.type === 'string') {
                            return _jsx(SectionString, { param: param }, param.varName);
                        }
                        if (param.type === 'boolean') {
                            return _jsx(SectionBoolean, { param: param }, param.varName);
                        }
                        if (param.type === 'expression') {
                            return _jsx(SectionExpression, { param: param }, param.varName);
                        }
                        // @ts-ignore
                        console.log('rendering unknown param type', param.type, param);
                        return _jsxs("div", { style: { opacity: 0.2 }, children: [param.varName, ": ", param.value] });
                    }) })] }, idx))) });
});
