import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { userStore } from "../../../stores/LoginStore";
import { TextField } from "@mui/material";
import css from './BuildVolume.module.scss';
import CloseIcon from '@mui/icons-material/Close';
const inputProps = {
    step: 1,
    min: 10,
    max: 1000
};
export const BuildVolume = observer(function BuildVolume() {
    const volume = userStore.buildVolume;
    return _jsxs("div", { className: css.buildVolume, children: [_jsx(TextField, { className: css.input, label: "Width", value: volume.w, type: "number", onChange: e => userStore.setBuildVolume(parseInt(e.target.value), volume.l, volume.h), inputProps: inputProps, variant: "standard" }), _jsx(CloseIcon, {}), _jsx(TextField, { className: css.input, label: "Length", value: volume.l, type: "number", onChange: e => userStore.setBuildVolume(volume.w, parseInt(e.target.value), volume.h), inputProps: inputProps, variant: "standard" }), _jsx(CloseIcon, {}), _jsx(TextField, { className: css.input, label: "Height", value: volume.h, type: "number", onChange: e => userStore.setBuildVolume(volume.w, volume.l, parseInt(e.target.value)), inputProps: inputProps, variant: "standard" })] });
});
