// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../node_modules/vanilla-jsoneditor/themes/jse-theme-dark.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usTr6gWjUWaaRx2O9_Iu{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/JsonEditor/JsonEditor.module.scss"],"names":[],"mappings":"AAEA,sBACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vanillaJsoneditorReact": `usTr6gWjUWaaRx2O9_Iu`
};
export default ___CSS_LOADER_EXPORT___;
