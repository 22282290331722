import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from "react-dom/client";
import { toJS } from "mobx";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import './index.module.scss';
import { SnackbarProvider } from "notistack";
const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#0b0f19',
            paper: '#161628',
        },
        secondary: {
            main: '#f9d72c',
        }
    },
    components: {
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                leaveDelay: 250,
                unselectable: 'on',
                placement: 'top'
            }
        },
        MuiChip: {
            defaultProps: {
                variant: 'outlined'
            },
            styleOverrides: {
                root: {
                    borderRadius: 4,
                }
            }
        }
    }
});
for (const [key, value] of Object.entries(theme.palette)) {
    if (!(value instanceof Object && value))
        continue;
    for (const [key2, value2] of Object.entries(value)) {
        if (value2 && typeof value2 === 'string') {
            document.documentElement.style.setProperty(`--color-${key}-${key2}`, value2);
            console.log(`--color-${key}-${key2}`, value2);
        }
    }
}
function App() {
    return (_jsxs(ThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), _jsx(RouterProvider, { router: router })] }));
}
root.render(
// <React.StrictMode>
_jsx(SnackbarProvider, { maxSnack: 3, children: _jsx(App, {}) })
// </React.StrictMode>
);
// @ts-ignore
window.toJS = toJS;
