var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { createContext } from "../../util/context";
import { createSchemaBasedOnVariables, ScadPresetsValidator } from "../../components/scad/ScadPresetEditor/ScadParameterEditor";
import { userStore } from "../../stores/LoginStore";
import { parseScadVariables } from "../../util/parseScadVariables";
import { loadSTLAndTakeScreenshot } from "../../util/stlScreenshot";
const defaultDocument = {
    _id: '',
    code: '',
    variables: [],
    inReview: false,
    name: '',
    desc: '',
    origin: 'proprietary',
    externalLink: '',
    license: 'CC0 1.0',
    presets: [],
    rating: { count: 0, score: 0 },
    tags: [],
    createdAt: Date.now(),
    updatedAt: Date.now(),
    views: 0,
    downloads: 0,
    likes: 0
};
class CreateModelStore {
    get presetValidator() {
        return ScadPresetsValidator(createSchemaBasedOnVariables(this.document.variables));
    }
    get presetValidationErrors() {
        return this.presetValidator(this.document.presets.map(x => ({ name: x.name, variables: x.variables })));
    }
    constructor() {
        this.document = structuredClone(defaultDocument);
        this.submittedData = null;
        this.submitting = false;
        this.submitError = null;
        this.step = 0;
        this.nextStep = () => this.step++;
        this.prevStep = () => this.step--;
        this.submit = async (stlFile, stlFileUrl) => {
            runInAction(() => {
                this.step = 9999;
                this.submitting = true;
                this.submitError = null;
            });
            const screenshotBase64 = await loadSTLAndTakeScreenshot(stlFileUrl, '#f9d72c');
            const screenshotRaw = atob(screenshotBase64.substring('data:image/png;base64,'.length));
            const screenshotFile = new Blob([Uint8Array.from(screenshotRaw, c => c.charCodeAt(0))], { type: 'image/png' });
            const formData = new FormData();
            formData.append('document', JSON.stringify(this.document));
            formData.append('stl', stlFile);
            formData.append('png', screenshotFile);
            try {
                const r = await userStore.authenticatedFetch(`/scad`, {
                    method: 'PUT',
                    body: formData,
                });
                const data = await r.json();
                runInAction(() => {
                    this.submittedData = data;
                });
                return data;
            }
            catch (e) {
                runInAction(() => {
                    this.submitError = e;
                });
                throw e;
            }
            finally {
                runInAction(() => this.submitting = false);
            }
        };
        this.setSource = (source) => this.document.code = source;
        this.setParameterSet = (parameterSet) => {
            let variables = [];
            try {
                variables = parseScadVariables(this.document.code);
                variables = variables.filter(x => x.sectionName.toLowerCase() !== 'hidden');
            }
            catch (e) {
                console.error('Failed to parse variables from the code', e);
                console.error('Falling back to WASM implementation');
                const v = parameterSet.parameters?.map(p => ({
                    type: (p.type === 'number' && Array.isArray(p.initial)
                        ? 'vector'
                        : p.type),
                    value: p.initial,
                    default: p.initial,
                    varName: p.name,
                    prettyName: p.name,
                    desc: p.caption?.trim() ?? `Parameter ${p.name}`,
                    ...(p.type === 'number' && ('min' in p || 'max' in p || 'step' in p)
                        ? {
                            max: p.max ?? 100,
                            min: p.min ?? 0,
                            step: p.step ?? 1,
                        }
                        : {})
                })) ?? [];
                const associatedGroups = parameterSet.parameters?.map(p => p.group ?? "Default Parameter Group") ?? [];
                const allGroups = Array.from(new Set(associatedGroups));
                variables = allGroups.map(group => ({
                    sectionName: group,
                    variables: v.filter((v, i) => associatedGroups[i] === group)
                }));
            }
            // if it's the first time we generate
            if (this.document.variables.length === 0) {
                this.document.variables = variables;
            }
            // attempt to add new variables
            else {
                const existingSection = new Set(this.document.variables.map(v => v.variables).flat().map(x => x.varName));
                const newVariables = variables
                    .map(g => g.variables.map(v => [v, g.sectionName]))
                    .flat()
                    .filter(([v, group]) => !existingSection.has(v.varName));
                for (const [v, group] of newVariables) {
                    const section = this.document.variables.find(s => s.sectionName === group);
                    if (section) {
                        section.variables.push(v);
                    }
                    else {
                        this.document.variables.push({
                            sectionName: group,
                            variables: [v]
                        });
                    }
                }
            }
        };
        this.setVariables = (variables) => {
            this.document.variables = variables;
        };
        this.setPresets = (presets) => {
            this.document.presets = presets;
        };
        this.createDefaultPreset = () => {
            if (this.document.presets.length) {
                return;
            }
            const variables = {};
            for (const section of this.document.variables) {
                for (const variable of section.variables) {
                    variables[variable.varName] = variable.default;
                }
            }
            this.document.presets.push({
                rating: { count: 0, score: 0 },
                added: new Date(),
                inReview: false,
                name: 'Default',
                variables,
            });
        };
        this.setName = (name) => this.document.name = name;
        this.setDescription = (desc) => this.document.desc = desc;
        this.setTags = (tags) => this.document.tags = tags;
        this.setOrigin = (origin) => this.document.origin = origin;
        this.setExternalLink = (link) => this.document.externalLink = link;
        this.setLicense = (license) => this.document.license = license;
        makeObservable(this);
        // @ts-ignore
        window.createModelStore = this;
    }
    get isExternalLinkValid() {
        if (this.document.origin === 'proprietary') {
            return true;
        }
        try {
            new URL(this.document.externalLink ?? '');
            return true;
        }
        catch {
            return false;
        }
    }
}
__decorate([
    observable
], CreateModelStore.prototype, "document", void 0);
__decorate([
    observable
], CreateModelStore.prototype, "submittedData", void 0);
__decorate([
    observable
], CreateModelStore.prototype, "submitting", void 0);
__decorate([
    observable
], CreateModelStore.prototype, "submitError", void 0);
__decorate([
    observable
], CreateModelStore.prototype, "step", void 0);
__decorate([
    computed
], CreateModelStore.prototype, "presetValidator", null);
__decorate([
    computed
], CreateModelStore.prototype, "presetValidationErrors", null);
__decorate([
    action
], CreateModelStore.prototype, "nextStep", void 0);
__decorate([
    action
], CreateModelStore.prototype, "prevStep", void 0);
__decorate([
    action
], CreateModelStore.prototype, "setSource", void 0);
__decorate([
    action
], CreateModelStore.prototype, "setParameterSet", void 0);
__decorate([
    action
], CreateModelStore.prototype, "setVariables", void 0);
__decorate([
    action
], CreateModelStore.prototype, "setPresets", void 0);
__decorate([
    action
], CreateModelStore.prototype, "createDefaultPreset", void 0);
__decorate([
    action
], CreateModelStore.prototype, "setName", void 0);
__decorate([
    action
], CreateModelStore.prototype, "setDescription", void 0);
__decorate([
    action
], CreateModelStore.prototype, "setTags", void 0);
__decorate([
    action
], CreateModelStore.prototype, "setOrigin", void 0);
__decorate([
    action
], CreateModelStore.prototype, "setExternalLink", void 0);
__decorate([
    computed
], CreateModelStore.prototype, "isExternalLinkValid", null);
__decorate([
    action
], CreateModelStore.prototype, "setLicense", void 0);
export const TagsAutoComplete = [
    '3D Printers',
    'Prusa Parts & Upgrades',
    'Accessories',
    'Anycubic Parts & Upgrades',
    'Bambu Lab Parts & Upgrades',
    'Creality Parts & Upgrades',
    'Other Printer Parts & Upgrades',
    'Voron Parts & Upgrades',
    'Test Models',
    'Art & Design',
    '2D Plates & Logos',
    'Sculptures',
    'Wall-mounted',
    'Other Art & Designs',
    'Costumes & Accessories',
    'Cosplay & Costumes in general',
    'Masks',
    'Props',
    'Other Costume Accessories',
    'Fashion',
    'Men',
    'Women',
    'Other Fashion Accessories',
    'Gadgets',
    'Audio',
    'Computers',
    'Photo & Video',
    'Portable Devices',
    'Video Games',
    'Virtual Reality',
    'Other Gadgets',
    'Healthcare',
    'Home Medical Tools',
    'Medical Tools',
    'Hobby & Makers',
    'Automotive',
    'Electronics',
    'Mechanical Parts',
    'Music',
    'Organizers',
    'RC & Robotics',
    'Tools',
    'Other Ideas',
    'Household',
    'Bathroom',
    'Bedroom',
    'Garage',
    'Home Decor',
    'Kitchen',
    'Living Room',
    'Office',
    'Outdoor & Garden',
    'Other House Equipment',
    'Pets',
    'Learning',
    'Chemistry & Biology',
    'Engineering',
    'Haptic Models',
    'Math',
    'Other 3D Objects for Learning',
    'Physics & Astronomy',
    'Seasonal designs',
    'Autumn & Halloween',
    'Spring & Easter',
    'Summer',
    "Winter & Christmas & New Year's",
    'Sports & Outdoor',
    'Indoor Sports',
    'Other Sports',
    'Outdoor Sports',
    'Winter Sports',
    'Tabletop Miniatures',
    'Characters & Monsters',
    'Miniature Gaming Accessories',
    'Props & Terrains',
    'Vehicles & Machines',
    'Toys & Games',
    'Action Figures & Statues',
    'Board Games',
    'Building Toys',
    'Outdoor Toys',
    'Puzzles & Brain-teasers',
    'Vehicles',
    'Other Toys & Games',
    'World & Scans',
    'Animals',
    'Architecture & Urbanism',
    'Historical Context',
    'People'
].map(x => x.toLowerCase());
export const { useStore: useCreateModelStore, Provider: CreateModelProvider } = createContext(CreateModelStore);
