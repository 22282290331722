import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { Button, CircularProgress, InputBase, Paper, TextField, Typography } from "@mui/material";
import css from "./Login.module.scss";
import { useCallback, useEffect, useState } from "react";
import { userStore } from "../../stores/LoginStore";
import { useNavigate } from "react-router-dom";
export const LoginPage = observer(() => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [challengeToken, setChallengeToken] = useState('');
    const [code, setCode] = useState('');
    const nav = useNavigate();
    useEffect(() => {
        emailError && setEmailError(false);
    }, [email]);
    const requestCode = useCallback(async () => {
        const emailIsValid = email.match(/.+@.+\..+/);
        setEmailError(!emailIsValid);
        if (!emailIsValid)
            return;
        setFetching(true);
        try {
            const token = await userStore.loginStart(email.trim());
            setChallengeToken(token);
        }
        finally {
            setFetching(false);
        }
    }, [email, setFetching, setChallengeToken, setEmailError]);
    const login = useCallback(async () => {
        try {
            setFetching(true);
            await userStore.loginComplete(challengeToken, code);
            nav('/');
        }
        finally {
            setFetching(false);
        }
    }, [challengeToken, code, setFetching]);
    return (_jsxs(Paper, { className: css.root, children: [_jsx(Typography, { variant: "h5", className: css.title, children: "Login to ScadHub.co" }), _jsx(TextField, { label: "Email Address", variant: "outlined", autoFocus: !challengeToken, fullWidth: true, placeholder: "person@example.com", value: email, onChange: e => setEmail(e.target.value), error: emailError, helperText: emailError && "Please enter a valid email address", disabled: fetching || !!challengeToken, autoComplete: "email", inputProps: {
                    "aria-autocomplete": 'both',
                }, name: "email", onKeyDown: e => {
                    if (e.key === 'Enter') {
                        requestCode().catch(console.error);
                    }
                } }), challengeToken && (_jsxs(_Fragment, { children: [_jsx(Typography, { className: css.challengeCodeLabel, children: "Check your email for a verification code." }), _jsx(InputBase, { autoFocus: true, fullWidth: true, placeholder: "000000", disabled: fetching, inputProps: {
                            pattern: "[0-9]{6}",
                            className: css.challengeCode,
                            type: 'tel',
                        }, value: code, onChange: e => {
                            const value = e.target.value;
                            if (value.match(/^[0-9]{0,6}$/)) {
                                setCode(value);
                            }
                        }, onKeyDown: e => {
                            if (e.key === 'Enter') {
                                login().catch(console.error);
                            }
                        } })] })), _jsxs("div", { className: css.actionContainer, children: [!challengeToken && (_jsx(Button, { variant: "contained", disabled: fetching || emailError, onClick: requestCode, startIcon: fetching && _jsx(CircularProgress, { size: 20 }), children: "Send Code" })), challengeToken && (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "contained", disabled: fetching, onClick: login, startIcon: fetching && _jsx(CircularProgress, { size: 20 }), children: "Login" }), _jsx(Button, { variant: "text", disabled: fetching, onClick: () => {
                                    setChallengeToken('');
                                    setCode('');
                                }, children: "Change Email" })] }))] })] }));
});
