import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { ModelListProvider, useModelList } from "./ModelListStore";
import { observer } from "mobx-react-lite";
import { Chip, CircularProgress, Paper, Tooltip, Typography } from "@mui/material";
import css from './ModelList.module.scss';
import { STLViewer } from "../../components/scad/STLViewer/STLViewer";
import { CDN_URL } from "../../util/env";
import { Grid, useGrid } from "@virtual-grid/react";
import { useElementSize } from "../../util/hooks";
import { Link, useSearchParams } from "react-router-dom";
import LabelIcon from '@mui/icons-material/Label';
import PageviewIcon from '@mui/icons-material/Pageview';
import DownloadIcon from '@mui/icons-material/Download';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Licenses } from "../../api/types/ScadDocument";
function formatCountable(views) {
    views = views || 0;
    if (views < 1000) {
        return views;
    }
    if (views < 1000000) {
        return `${(views / 1000).toFixed(1)}k`;
    }
    return `${(views / 1000000).toFixed(1)}m`;
}
export const ModelListItem = observer(function ModelListItem(props) {
    const { model } = props;
    const [interactive, setInteractive] = useState(false);
    const [modelLoaded, setModelLoaded] = useState(false);
    useEffect(() => {
        if (interactive) {
            setModelLoaded(false);
        }
    }, [interactive]);
    return (_jsxs(Paper, { elevation: 5, className: css.item, onMouseEnter: () => setInteractive(true), onMouseLeave: () => setInteractive(false), children: [!interactive && (_jsx("img", { src: `${CDN_URL}/documents/${model._id}/image.png`, className: css.image, onError: (e) => {
                    console.error(e);
                    setInteractive(i => !i);
                } })), interactive && (_jsxs(_Fragment, { children: [_jsx(STLViewer, { fileUrl: `${CDN_URL}/documents/${model._id}/file.stl`, className: css.viewer, interactive: interactive, onReady: (model, camera, screenshot) => {
                            camera.setCameraPosition({
                                latitude: 25 * Math.PI / 180,
                                longitude: (90 + 45) * Math.PI / 180,
                                distance: 2,
                            });
                            setModelLoaded(true);
                        }, onError: (e) => {
                            console.error(e);
                            setInteractive(i => !i);
                        } }), !modelLoaded && (_jsx("div", { className: css.centered, children: _jsx(CircularProgress, { size: 64, variant: "indeterminate" }) }))] })), _jsxs(Paper, { className: css.info, elevation: 10, children: [_jsx(Link, { to: `/model/${model._id}`, className: css.title, children: model.name }), _jsxs("div", { className: css.actions, children: [_jsx(Tooltip, { classes: { tooltip: css.tags }, title: model.tags.map((t, i) => _jsx(Chip, { label: t, size: "small", variant: "outlined", color: "secondary" }, i)), children: _jsxs(Typography, { display: "flex", children: [model.tags.length, " ", _jsx(LabelIcon, {}), " "] }) }), _jsx(Tooltip, { title: `Views: ${formatCountable(model.views)}`, children: _jsxs(Typography, { display: "flex", children: [formatCountable(model.views), _jsx(PageviewIcon, {}), " "] }) }), _jsx(Tooltip, { title: `Downloads: ${formatCountable(model.downloads)}`, children: _jsxs(Typography, { display: "flex", children: [formatCountable(model.downloads), _jsx(DownloadIcon, {}), " "] }) }), _jsx(Tooltip, { title: `License: ${Licenses.find(x => x.code === model.license)?.name || model.license}`, children: _jsxs(Typography, { display: "flex", children: [model.license, _jsx(CopyrightIcon, {}), " "] }) })] })] })] }));
});
export const ModelList = observer(function ModelList() {
    const models = useModelList();
    const { list } = models;
    const [queryParams, setQueryParams] = useSearchParams();
    models.setSearch(queryParams.get('q') || '');
    const [boxRef, { width, height }] = useElementSize();
    const grid = useGrid({
        scrollRef: boxRef,
        count: models.list.length,
        width,
        height,
        size: {
            height: 500,
            width: 400,
        },
        columns: 'auto',
        onLoadMore: models.canLoadMore ? models.loadMore : undefined,
    });
    return (_jsxs("div", { className: css.list, children: [models.fetching &&
                _jsx("div", { className: css.centered, children: _jsx(CircularProgress, { size: 64, variant: "indeterminate" }) }), !models.fetching && models.list.length === 0 && models.search.length > 0 && !models.searchStale &&
                _jsxs(Typography, { variant: "h4", className: css.centered, children: ["Nothing found for \"", models.search, "\""] }), _jsx("div", { className: css.gridContainer, ref: boxRef, children: _jsx(Grid, { grid: grid, children: i => _jsx("div", { className: css.itemContainer, children: _jsx(ModelListItem, { model: list[i] }, list[i]._id) }) }) })] }));
});
export function ModelListPage() {
    return (_jsx(ModelListProvider, { children: _jsx(ModelList, {}) }));
}
