import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { SvelteJSONEditor } from "../../JsonEditor/JsonEditor";
import { createAjvValidator } from "vanilla-jsoneditor";
export function createSchemaBasedOnVariables(variables) {
    const properties = {};
    for (const section of variables) {
        for (const variable of section.variables) {
            if (variable.type === 'vector') {
                properties[variable.varName] = { type: 'array', items: { type: 'number' } };
            }
            else if (variable.type === 'expression') {
                properties[variable.varName] = { type: 'string' };
            }
            else {
                properties[variable.varName] = { type: variable.type };
            }
        }
    }
    return {
        "$schema": "http://json-schema.org/draft-07/schema#",
        type: 'array',
        items: {
            type: 'object',
            properties: {
                name: { type: 'string' },
                variables: {
                    type: 'object',
                    properties,
                    "additionalProperties": false
                }
            },
            "required": ["name", "variables"],
            "additionalProperties": false
        }
    };
}
export const ScadPresetsValidator = (schema) => createAjvValidator({
    schema,
    ajvOptions: {
        allErrors: true,
        verbose: true,
        code: {
            lines: true,
            source: true
        }
    }
});
// todo DRY this component
export const ScadPresetEditor = observer(function ScadPresetEditor(props) {
    const { presets, className, setData, validator } = props;
    const strippedPresets = presets.map(preset => {
        return { name: preset.name, variables: preset.variables };
    });
    return _jsx(SvelteJSONEditor, { onChange: (content, previousContent, status) => {
            console.log('onChange', content, previousContent, status);
            if (status.contentErrors) {
                console.error('contentErrors', status.contentErrors);
                if ('parseError' in status.contentErrors) {
                    return;
                }
            }
            let data = [];
            if ('json' in content && content.json) {
                data = content.json;
            }
            if ('text' in content && content.text) {
                try {
                    data = JSON.parse(content.text);
                }
                catch (e) {
                    console.error(e);
                }
            }
            if (data) {
                setData(data.map(sp => ({
                    name: sp.name,
                    variables: sp.variables,
                    inReview: false,
                    added: new Date(),
                    rating: { count: 0, score: 0 },
                })));
            }
        }, content: { json: strippedPresets }, validator: validator });
});
