// Portions of this file are Copyright 2021 Google LLC, and licensed under GPL2+. See COPYING.
import { AbortablePromise } from "../utils";
export function createWasmMemory({ maximumMegabytes, maximumBytes }) {
    const pageSize = 64 * 1024; // 64KB
    if (!maximumBytes) {
        maximumBytes = maximumMegabytes * 1024 * 1024;
    }
    return new WebAssembly.Memory({
        initial: Math.floor(maximumBytes / 2 / pageSize),
        maximum: Math.floor(maximumBytes / pageSize),
        shared: true,
    });
}
export function spawnOpenSCAD(invocation, pipeCb) {
    var worker;
    var rejection;
    function terminate() {
        if (!worker) {
            return;
        }
        worker.terminate();
        worker = null;
    }
    return AbortablePromise((resolve, reject) => {
        worker = new Worker('/openscad-worker.js'); //, {type: "module"})
        // if (navigator.userAgent.indexOf(' Chrome/') < 0) {
        //   worker = new Worker('/openscad-worker-firefox.js'); // {'type': 'module'}
        // } else {
        //   worker = new Worker('/openscad-worker.js', {'type': 'module'});
        // }
        rejection = reject;
        worker.onmessage = (e) => {
            if ('pipe' in e.data && 'message' in e.data) {
                pipeCb?.(e.data.pipe, e.data.message);
                // console.log(e.data.pipe, e.data.message);
                return;
            }
            resolve(e.data);
            terminate();
            return;
        };
        console.log('spawnOpenSCAD', invocation);
        worker.postMessage(invocation);
        return () => {
            // rejection({error: 'Terminated'});
            terminate();
        };
    });
}
