import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { EditorFSProvider } from "../../stores/fs";
import { ModelProvider, useModel } from "../../stores/model";
import { CreateModelProvider, TagsAutoComplete, useCreateModelStore } from "./CreateModelStore";
import { useRef } from "react";
import { Autocomplete, Button, Chip, CircularProgress, MenuItem, MobileStepper, Paper, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { EditorPanelWithSTLViewer } from "../../components/scad/Editor/Editor";
import { ScadEditor } from "../../components/scad/ScadEditor/ScadEditor";
import { useElementSize } from "../../util/hooks";
import { ScadParameterEditor, ScadParameterValidator } from "../../components/scad/ScadParameterEditor/ScadParameterEditor";
import { ScadPresetEditor } from "../../components/scad/ScadPresetEditor/ScadParameterEditor";
import { Licenses } from "../../api/types/ScadDocument";
import css from './CreateNewModel.module.scss';
import Editor from "react-simple-wysiwyg";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { useNavigate } from "react-router-dom";
import { resetStore } from "../../util/context";
const ParametersEditor = observer(() => {
    const scadModel = useCreateModelStore();
    const { variables } = scadModel.document;
    return _jsx(ScadParameterEditor, { variables: variables, setData: scadModel.setVariables });
});
const PresetsEditor = observer((props) => {
    const scadModel = useCreateModelStore();
    const { presets } = scadModel.document;
    return _jsx(ScadPresetEditor, { validator: props.validator, presets: presets, setData: scadModel.setPresets });
});
const steps = [
    {
        name: 'Add your OpenSCAD File',
        componentFactory: (props) => (_jsx(ScadEditor, { width: props.width, height: props.height })),
        disabledNext: (scadModel, model) => model.previewing || model.rendering || !!model.error || !model.source,
        beforeNext: (scadModel, model) => {
            scadModel.setSource(model.source ?? '');
            if (model.parameterSet) {
                scadModel.setParameterSet(model.parameterSet);
            }
            model.renderAsync({ now: true, isPreview: false }).catch(console.error);
        }
    },
    {
        name: 'Define and adjust the parameters',
        componentFactory: (props) => (_jsx("div", { style: { overflow: 'auto', height: props.height, width: props.width, display: 'flex' }, children: _jsx(ParametersEditor, {}) })),
        disabledNext: (scadModel) => ScadParameterValidator(scadModel.document.variables).length > 0,
        beforeNext: (scadModel) => scadModel.createDefaultPreset()
    },
    {
        name: 'Add Presets',
        componentFactory: (props) => (_jsx("div", { style: { overflow: 'auto', height: props.height, width: props.width, display: 'flex' }, children: _jsx(PresetsEditor, { validator: props.scadModel.presetValidator }) })),
        disabledNext: (scadModel) => scadModel.presetValidationErrors.length > 0,
    },
    {
        name: 'Add Details about your model',
        componentFactory: ({ height, scadModel, width }) => (_jsxs("div", { className: css.stepDetails, style: {
                overflowY: 'auto',
                overflowX: 'hidden',
                height,
                width
            }, children: [_jsx(TextField, { variant: "outlined", placeholder: "Name", label: "Your Model Name", value: scadModel.document.name, onChange: e => scadModel.setName(e.target.value), required: true }), _jsx("div", { className: css.descriptionContainer, children: _jsx(Editor, { value: scadModel.document.desc, onChange: e => scadModel.setDescription(e.target.value) }) }), _jsx(Autocomplete, { multiple: true, id: "tags-filled", options: TagsAutoComplete, defaultValue: [], value: scadModel.document.tags, onChange: (event, newValue) => {
                        scadModel.setTags(newValue);
                    }, freeSolo: true, renderInput: (params) => (_jsx(TextField, { ...params, variant: "outlined", label: "Tags to describe your model", placeholder: "tag" })) }), _jsxs(ToggleButtonGroup, { color: "primary", value: scadModel.document.origin, exclusive: true, onChange: (e, v) => scadModel.setOrigin(v), children: [_jsxs(ToggleButton, { value: "proprietary", style: { display: 'flex', flexFlow: 'column' }, children: [_jsx(Typography, { variant: "h6", fontWeight: "bold", children: "My Own" }), _jsx(Typography, { variant: "body2", children: "This is my own work" })] }), _jsxs(ToggleButton, { value: "remix", style: { display: 'flex', flexFlow: 'column' }, children: [_jsx(Typography, { variant: "h6", fontWeight: "bold", children: "Remix" }), _jsx(Typography, { variant: "body2", children: "This is a remix or variation of another work" })] }), _jsxs(ToggleButton, { value: "re-upload", style: { display: 'flex', flexFlow: 'column' }, children: [_jsx(Typography, { variant: "h6", fontWeight: "bold", children: "Re-Upload" }), _jsx(Typography, { variant: "body2", children: "I am re-uploading a model respecting the original license" })] })] }), _jsx(Select, { variant: "outlined", placeholder: "License", label: "License", value: scadModel.document.license, onChange: e => scadModel.setLicense(e.target.value), required: true, children: Licenses.map(({ code, name }) => (_jsx(MenuItem, { value: code, children: name }, code))) }), _jsx(TextField, { variant: "outlined", placeholder: "External Link", label: "External Link", value: scadModel.document.externalLink, onChange: e => scadModel.setExternalLink(e.target.value), required: scadModel.document.origin !== 'proprietary', error: !scadModel.isExternalLinkValid, helperText: !scadModel.isExternalLinkValid ? 'Please enter a valid URL' : '' })] })),
        disabledNext: scadModel => !(scadModel.document.name.trim().length >= 3 &&
            scadModel.document.desc.trim().length >= 10 &&
            scadModel.document.tags.length > 0 &&
            scadModel.isExternalLinkValid)
    }
];
export const CreateNewModelPageWithContext = observer(function CreateNewModelPageWithContext() {
    const scadModel = useCreateModelStore();
    const model = useModel();
    const nav = useNavigate();
    const [boxRef, { width: boxWidth, height: boxHeight }] = useElementSize();
    const stlRef = useRef(null);
    return (_jsx(EditorPanelWithSTLViewer, { ref: stlRef, children: _jsxs(Paper, { elevation: 5, style: { width: '100%', height: '100%', display: 'flex', flexFlow: 'column' }, children: [scadModel.step < steps.length && (_jsxs(_Fragment, { children: [_jsxs(Typography, { variant: "h6", style: { padding: 8 }, children: [_jsx(Chip, { label: scadModel.step + 1, color: "primary", style: { marginRight: 4 } }), steps[scadModel.step].name] }), _jsx("div", { ref: boxRef, style: { flexGrow: 1, flexShrink: 1, overflow: "hidden" }, children: steps[scadModel.step].componentFactory({ width: boxWidth, height: boxHeight, scadModel, model }) }), _jsx(MobileStepper, { variant: "progress", steps: steps.length, position: "static", activeStep: scadModel.step, nextButton: _jsxs(Button, { size: "small", onClick: () => {
                                    steps[scadModel.step].beforeNext?.(scadModel, model);
                                    if (scadModel.step + 1 === steps.length) {
                                        scadModel.submit(model.output.outFile, model.output.outFileURL).catch(console.error);
                                    }
                                    else {
                                        scadModel.nextStep();
                                    }
                                }, disabled: steps[scadModel.step].disabledNext?.(scadModel, model), children: [scadModel.step + 1 === steps.length ? 'Submit' : 'Next', _jsx(KeyboardArrowRight, {})] }), backButton: _jsxs(Button, { size: "small", onClick: scadModel.prevStep, disabled: scadModel.step === 0, children: [_jsx(KeyboardArrowLeft, {}), "Back"] }) })] })), scadModel.step >= steps.length && (_jsxs("div", { className: css.submitted, children: [scadModel.submitting
                            ? _jsx(CircularProgress, { size: 48, variant: "indeterminate" })
                            : _jsx(CloudDoneIcon, {}), _jsxs(Typography, { variant: "h4", style: { padding: 8 }, align: "center", children: [scadModel.submitting && 'Submitting...', scadModel.submittedData && 'Your model has been submitted', scadModel.submitError && _jsxs(_Fragment, { children: ["An error occurred while submitting", _jsx(Typography, { children: scadModel.submitError.message }), _jsx(Button, { onClick: () => {
                                                scadModel.submit(model.output.outFile, model.output.outFileURL).catch(console.error);
                                            }, children: "Try Again" })] })] }), !scadModel.submitting && (_jsxs("div", { children: [_jsx(Button, { variant: "contained", color: "primary", onClick: () => {
                                        resetStore(model);
                                        resetStore(scadModel);
                                    }, children: "Submit another model" }), _jsx(Button, { variant: "outlined", onClick: () => nav('/'), children: "Done" })] }))] }))] }) }));
});
export const CreateNewModelPage = observer(() => {
    return (_jsx(CreateModelProvider, { children: _jsx(EditorFSProvider, { children: _jsx(ModelProvider, { children: _jsx(CreateNewModelPageWithContext, {}) }) }) }));
});
