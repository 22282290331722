// Portions of this file are Copyright 2021 Google LLC, and licensed under GPL2+. See COPYING.
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { join, readDirAsArray } from '../fs/filesystem';
import { parseOpenSCAD, stripComments } from './openscad-pseudoparser';
import builtinSignatures from './openscad-builtins';
import { mapObject } from '../utils';
import openscadLanguage from './openscad-language';
function makeFunctionoidSuggestion(name, mod) {
    const argSnippets = [];
    const namedArgs = [];
    let collectingPosArgs = true;
    let i = 0;
    for (const param of mod.params ?? []) {
        if (collectingPosArgs) {
            if (param.defaultValue != null) {
                collectingPosArgs = false;
            }
            else {
                //argSnippets.push(`${param.name}=${'${' + (i + 1) + ':' + param.name + '}'}`);
                argSnippets.push(`${param.name.replaceAll('$', '\\$')}=${'${' + (++i) + ':' + param.name + '}'}`);
                continue;
            }
        }
        namedArgs.push(param.name);
    }
    if (namedArgs.length) {
        argSnippets.push(`${'${' + (++i) + ':' + namedArgs.join('|') + '=}'}`);
    }
    let insertText = `${name.replaceAll('$', '\\$')}(${argSnippets.join(', ')})`;
    if (mod.referencesChildren !== null) {
        insertText += mod.referencesChildren ? ' ${' + (++i) + ':children}' : ';';
    }
    return {
        label: mod.signature, //`${name}(${(mod.params ?? []).join(', ')})`,
        kind: monaco.languages.CompletionItemKind.Function,
        insertText,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
    };
}
const builtinCompletions = [
    ...[true, false].map(v => ({
        label: `${v}`,
        kind: monaco.languages.CompletionItemKind.Value,
        insertText: `${v}`,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
    })),
    ...openscadLanguage.language.keywords.map((v) => ({
        label: v,
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: v,
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
    }))
];
const keywordSnippets = [
    'for(${1:variable}=[${2:start}:${3:end}) ${4:body}',
    'for(${1:variable}=[${2:start}:${3:increment}:${4:end}) ${5:body}',
    'if (${1:condition}) {\n\t$0\n} else {\n\t\n}'
];
function cleanupVariables(snippet) {
    return snippet
        .replaceAll(/\$\{\d+:([$\w]+)\}/g, '$1')
        .replaceAll(/\$\d+/g, '')
        .replaceAll(/\s+/g, ' ')
        .trim();
}
// https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-custom-languages
export async function buildOpenSCADCompletionItemProvider(fs, workingDir, zipArchives) {
    const parsedFiles = {};
    const toAbsolutePath = (path) => path.startsWith('/') ? path : `${workingDir}/${path}`;
    const allSymlinks = {};
    for (const [n, { deployed, symlinks }] of Object.entries(zipArchives)) {
        if (n == 'fonts') {
            continue;
        }
        if (deployed === false) {
            continue;
        }
        for (const s in symlinks) {
            allSymlinks[s] = `${n}/${symlinks[s]}`;
        }
    }
    async function readFile(path) {
        if (path in allSymlinks) {
            path = allSymlinks[path];
        }
        path = toAbsolutePath(path);
        try {
            const bytes = await fs.readFileSync(path);
            const src = new TextDecoder("utf-8").decode(bytes);
            return src;
        }
        catch (e) {
            throw e;
        }
    }
    const builtinsPath = '<builtins>';
    let builtinsDefs;
    function getParsed(path, src, { skipPrivates, addBuiltins }) {
        return parsedFiles[path] ??= new Promise(async (res, rej) => {
            if (src == null) {
                src = await readFile(path);
            }
            const result = {
                functions: {},
                modules: {},
                vars: [],
                includes: [],
                uses: [],
            };
            const mergeDefinitions = (isUse, defs) => {
                result.functions = { ...result.functions, ...defs.functions };
                result.modules = { ...result.modules, ...defs.modules };
                if (!isUse) {
                    result.vars = [...result.vars, ...defs.vars];
                }
            };
            const dir = (path.split('/').slice(0, -1).join('/') || '.') + '/';
            const handleInclude = async (isUse, otherPath) => {
                let found = false;
                for (const option of [`/libraries/${otherPath}`, `${dir}/${otherPath}`, otherPath]) {
                    try {
                        const otherSrc = await readFile(option);
                        const sub = await getParsed(otherPath, otherSrc, { skipPrivates: true, addBuiltins: false });
                        mergeDefinitions(isUse, sub);
                        found = true;
                        break;
                    }
                    catch (e) {
                        console.warn(`Failed to read file option ${option} for ${otherPath} ${isUse ? 'used' : 'included'} by ${path}`, e);
                    }
                }
                if (!found) {
                    console.error('Failed to find ', otherPath, '(context imported in ', path, ')');
                }
            };
            if (addBuiltins && path != builtinsPath) {
                mergeDefinitions(false, builtinsDefs);
            }
            const ownDefs = parseOpenSCAD(path, src, skipPrivates);
            await Promise.all([
                ...(ownDefs.uses ?? []).map(p => [p, true]),
                ...(ownDefs.includes ?? []).map(p => [p, false])
            ].map(([otherPath, isUse]) => handleInclude(isUse, otherPath)));
            mergeDefinitions(false, ownDefs);
            res(result);
        });
    }
    builtinsDefs = await getParsed(builtinsPath, builtinSignatures, { skipPrivates: false, addBuiltins: false });
    return {
        triggerCharacters: ["<", "/"], //, "\n"],
        //provideCompletionItems: (async (model, position, context, token) => {
        provideCompletionItems: (async (model, position, context, token) => {
            try {
                const { word } = model.getWordUntilPosition(position);
                const offset = model.getOffsetAt(position);
                const text = model.getValue();
                let previous = text.substring(0, offset);
                let i = previous.lastIndexOf('\n');
                previous = previous.substring(i + 1);
                const includeMatch = /\b(include|use)\s*<([^<>\n"]*)$/.exec(previous);
                if (includeMatch) {
                    const prefix = includeMatch[2];
                    let folder, filePrefix, folderPrefix;
                    const i = prefix.lastIndexOf('/');
                    if (i < 0) {
                        folderPrefix = '';
                        filePrefix = prefix;
                    }
                    else {
                        folderPrefix = prefix.substring(0, i);
                        filePrefix = prefix.substring(i + 1);
                    }
                    const folderName = (folderPrefix == '' ? '' : '/' + folderPrefix);
                    let files = null;
                    for (const folder of [join('/libraries', folderName), join(workingDir, folderName)]) {
                        files = folderPrefix == '' ? [...Object.keys(allSymlinks)] : [];
                        try {
                            files = [...(await readDirAsArray(fs, folder) ?? []), ...files];
                            // console.log('readDir', folder, files);
                            break;
                        }
                        catch (e) {
                            //console.error(e);
                        }
                    }
                    const suggestions = [];
                    if (!files) {
                        console.warn('Failed to find folder named ' + folderName);
                    }
                    else {
                        for (const file of files) {
                            if (filePrefix != '' && !file.startsWith(filePrefix)) {
                                continue;
                            }
                            if (/^(LICENSE.*|fonts)$/.test(file)) {
                                continue;
                            }
                            if (folderPrefix == '' && (file in zipArchives) && zipArchives[file].symlinks) {
                                continue;
                            }
                            const isFolder = !file.endsWith('.scad');
                            const completion = file + (isFolder ? '' : '>\n'); // don't append '/' as it's a useful trigger char
                            console.log(JSON.stringify({
                                prefix,
                                folder,
                                filePrefix,
                                folderPrefix,
                                // files,
                                completion,
                                file,
                            }, null, 2));
                            suggestions.push({
                                label: file,
                                kind: isFolder ? monaco.languages.CompletionItemKind.Folder : monaco.languages.CompletionItemKind.File,
                                insertText: completion
                            });
                        }
                    }
                    suggestions.sort();
                    return { suggestions };
                }
                const inputFile = join(workingDir, 'foo.scad');
                delete parsedFiles[inputFile];
                const parsed = await getParsed(inputFile, text, { skipPrivates: false, addBuiltins: true });
                console.log("PARSED", JSON.stringify(parsed, null, 2));
                const previousWithoutComments = stripComments(previous);
                // console.log('previousWithoutComments', previousWithoutComments);
                const statementMatch = /(^|.*?[{});]|>\s*\n)\s*([$\w]*)$/m.exec(previousWithoutComments);
                if (statementMatch) {
                    const start = statementMatch[1];
                    const suggestions = [
                        ...builtinCompletions,
                        ...mapObject(parsed.modules ?? {}, (name, mod) => makeFunctionoidSuggestion(name, mod), name => name.indexOf(word) >= 0),
                        ...((parsed.vars ?? []).filter(name => name.indexOf(word) >= 0).map(name => ({
                            label: name,
                            kind: monaco.languages.CompletionItemKind.Variable,
                            insertText: name.replaceAll('$', '\\$'),
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
                        }))),
                        ...keywordSnippets.map(snippet => ({
                            label: cleanupVariables(snippet).replaceAll(/ body/g, ''),
                            kind: monaco.languages.CompletionItemKind.Keyword,
                            insertText: snippet,
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
                        })),
                        // ...getStatementSuggestions().filter(s => start == '' || s.insertText.indexOf(start) >= 0)
                    ];
                    suggestions.sort((a, b) => a.insertText.indexOf(start) - b.insertText.indexOf(start));
                    return { suggestions };
                }
                const allWithoutComments = stripComments(text);
                const named = [
                    ...mapObject(parsed.functions ?? {}, (name, mod) => [name, makeFunctionoidSuggestion(name, mod)], name => name.indexOf(word) >= 0)
                ];
                named.sort(([a], [b]) => a.indexOf(word) - b.indexOf(word));
                // const suggestions = names.map(name => ({
                //   label: name,
                //   kind: monaco.languages.CompletionItemKind.Constant,
                //   insertText: name
                // }));
                const suggestions = named.map(([n, s]) => s);
                return { suggestions };
            }
            catch (e) {
                console.error(e); //, (e as any).stackTrace);
                return { suggestions: [] };
            }
        }),
    };
}
