import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { StlViewer } from "react-stl-viewer";
import { useCallback, useRef } from "react";
import { useElementSize } from "../../../util/hooks";
// todo need to preserve the camera position between loads... somehow
//      the library doesn't seem to support that
//
// fixme when build volume changes, there's no way to re-center the camera
export const STLViewer = observer((props) => {
    const stlModelRef = useRef(null);
    const cameraRef = useRef(null);
    const [boxRef, { width, height }] = useElementSize();
    // const [isFullscreen, setIsFullscreen] = useState(false);
    const screenshot = useCallback(() => {
        const canvas = boxRef.current?.querySelector('canvas');
        if (!canvas) {
            return null;
        }
        return canvas.toDataURL('image/png');
    }, [boxRef.current]);
    return _jsx("div", { className: props.className, ref: boxRef, style: { overflow: 'hidden', position: 'relative' }, children: props.fileUrl && (
        // todo find better STL viewer that allows for PAN too
        _jsx(StlViewer, { onError: props.onError, style: { width, height }, width: width, height: height, 
            // ref={stlModelRef}
            showAxes: props.interactive, orbitControls: props.interactive, shadows: true, modelProps: {
                positionX: props.buildPlateSize && props.centerModelOnBuildPlate ? props.buildPlateSize.gridWidth / 2 : 0,
                positionY: props.buildPlateSize && props.centerModelOnBuildPlate ? props.buildPlateSize.gridLength / 2 : 0,
                rotationX: 0,
                rotationY: 0,
                rotationZ: 0,
                scale: 1,
                color: props.color ?? '#f9d72c',
                ...props.modelProps,
                ref: stlModelRef,
            }, url: props.fileUrl, floorProps: props.buildPlateSize, cameraProps: {
                ref: cameraRef,
            }, onFinishLoading: (ev) => {
                setTimeout(() => {
                    //   there's a big where the model ref is not ready yet
                    props.onReady?.(stlModelRef.current, cameraRef.current, screenshot);
                }, 1);
            } })) });
});
