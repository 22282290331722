// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IKgcp3cXRV_ftUZfywzt{width:400px;display:flex;flex-direction:column;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);padding:20px}.IKgcp3cXRV_ftUZfywzt .H69Ev6_YlEYjPZaOuNr_{text-align:center;margin-bottom:20px}.IKgcp3cXRV_ftUZfywzt .qRsVL8rAKxJQNRjotED8{text-align:center;font-size:1.2em;margin-top:20px}.IKgcp3cXRV_ftUZfywzt .YsEBVkznypTF_y8__ruM{text-align:center;font-size:1.5em;padding:.5em;border:1px solid rgba(255,255,255,.3);border-radius:4px}.IKgcp3cXRV_ftUZfywzt .bSX4vH6ycYZsqftJlUtQ{margin-top:20px;display:flex;flex-flow:row-reverse}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.module.scss"],"names":[],"mappings":"AAAA,sBAEE,WAAA,CACA,YAAA,CACA,qBAAA,CAEA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CAEE,YAAA,CAEF,4CACE,iBAAA,CACA,kBAAA,CAGF,4CACE,iBAAA,CACA,eAAA,CACA,eAAA,CAEF,4CACE,iBAAA,CACA,eAAA,CACA,YAAA,CACA,qCAAA,CACA,iBAAA,CAGF,4CACE,eAAA,CACA,YAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `IKgcp3cXRV_ftUZfywzt`,
	"title": `H69Ev6_YlEYjPZaOuNr_`,
	"challengeCodeLabel": `qRsVL8rAKxJQNRjotED8`,
	"challengeCode": `YsEBVkznypTF_y8__ruM`,
	"actionContainer": `bSX4vH6ycYZsqftJlUtQ`
};
export default ___CSS_LOADER_EXPORT___;
