var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { DataFrame } from "../../util/dataframe";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { API_URL } from "../../util/env";
import { debounce } from "@mui/material";
import { createContext } from "../../util/context";
class ModelListStore extends DataFrame {
    constructor() {
        super({ autoFetch: true });
        this.list = [];
        this.searchStale = false;
        this.totalCount = Infinity;
        this.search = '';
        this.limit = 50;
        this.setSearch = (search) => this.search = search;
        this.reset = () => {
            this.list = [];
            this.totalCount = Infinity;
        };
        this.loadMore = () => {
            if (this.fetching)
                return;
            this.update().catch(console.error);
        };
        makeObservable(this);
        const queryParams = new URLSearchParams(window.location.search);
        this.search = queryParams.get('q') ?? '';
        reaction(() => this.search, action(() => this.searchStale = true));
        reaction(() => this.search, debounce(() => {
            this.reset();
            this.update().catch(console.error);
        }, 500));
        // @ts-ignore
        window.modelList = this;
    }
    get isUsingSearch() {
        return this.search.trim().length > 0;
    }
    get oldest() {
        if (!this.list.length)
            return null;
        const sorted = this.list.slice().sort((a, b) => b.createdAt - a.createdAt);
        return sorted[sorted.length - 1];
    }
    get canLoadMore() {
        if (this.isUsingSearch)
            return false;
        if (this.totalCount <= this.list.length)
            return false;
        return true;
    }
    async fetch(as) {
        const url = new URL(`${API_URL}/scad/list`);
        if (this.isUsingSearch) {
            url.searchParams.set('search', this.search.trim());
        }
        else {
            console.log('oldest', this.oldest);
            if (this.oldest) {
                url.searchParams.set('olderThan', this.oldest.createdAt.toString());
            }
            url.searchParams.set('limit', this.limit.toString());
        }
        const r = await fetch(url, { signal: as });
        if (!r.ok) {
            const e = await r.json();
            throw new Error(`Failed to fetch model list`, { cause: e });
        }
        const { data } = await r.json();
        runInAction(() => {
            this.list.push(...data);
            if (this.isUsingSearch) {
                this.totalCount = data.length;
            }
            else {
                if (data.length < this.limit) {
                    this.totalCount = this.list.length;
                }
            }
            this.searchStale = false;
        });
        return data;
    }
}
__decorate([
    observable
], ModelListStore.prototype, "list", void 0);
__decorate([
    observable
], ModelListStore.prototype, "searchStale", void 0);
__decorate([
    observable
], ModelListStore.prototype, "totalCount", void 0);
__decorate([
    observable
], ModelListStore.prototype, "search", void 0);
__decorate([
    observable
], ModelListStore.prototype, "limit", void 0);
__decorate([
    computed
], ModelListStore.prototype, "isUsingSearch", null);
__decorate([
    computed
], ModelListStore.prototype, "oldest", null);
__decorate([
    action
], ModelListStore.prototype, "setSearch", void 0);
__decorate([
    computed
], ModelListStore.prototype, "canLoadMore", null);
__decorate([
    action
], ModelListStore.prototype, "reset", void 0);
export const { useStore: useModelList, Provider: ModelListProvider } = createContext(ModelListStore);
