import * as THREE from 'three';
import { SRGBColorSpace } from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
function colorHexStringToNumber(colorHex) {
    return parseInt(colorHex.replace(/^#/, ''), 16);
}
export async function loadSTLAndTakeScreenshot(stlUrl, colorHex = '#f9d72c') {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true });
    renderer.outputColorSpace = SRGBColorSpace;
    renderer.setSize(1024, 1024);
    renderer.setClearColor(0x000000, 0); // Set clear color to transparent
    const loader = new STLLoader();
    const geometry = await new Promise((res, rej) => {
        loader.load(stlUrl, res, (p) => console.log('loading stl for screenshot', p), rej);
    });
    const material = new THREE.MeshPhongMaterial({
        color: colorHexStringToNumber(colorHex),
        // specular: 0x444444,
        specular: 0x0,
        shininess: 30,
        reflectivity: 1,
        emissive: 0x0,
        emissiveIntensity: 0.2
    });
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);
    // Center the model
    geometry.computeBoundingSphere();
    geometry.computeBoundingBox();
    const center = new THREE.Vector3();
    geometry.boundingBox.getCenter(center);
    mesh.position.sub(center);
    // Set up lighting
    const lightDistance = 350;
    const lights = [
        [0, 0, 0 + lightDistance],
        [-lightDistance + 0, 0, 0 + lightDistance],
        [0, -lightDistance + 0, 0 + lightDistance],
        [0, lightDistance + 0, 0]
    ];
    for (const light of lights) {
        const lightInstance = new THREE.DirectionalLight(0xffffff, 2);
        lightInstance.position.set(light[0], light[1], light[2]);
        scene.add(lightInstance);
    }
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);
    // Set camera position
    const radius = geometry.boundingSphere.radius * 2;
    const latitude = 25 * Math.PI / 180;
    const longitude = 45 * Math.PI / 180;
    camera.position.x = radius * Math.cos(latitude) * Math.cos(longitude);
    camera.position.y = radius * Math.cos(latitude) * Math.sin(longitude);
    camera.position.z = radius * Math.sin(latitude);
    camera.up.set(0, 0, 1);
    camera.lookAt(scene.position);
    // Render the scene
    renderer.render(scene, camera);
    // Capture screenshot
    const screenshot = renderer.domElement.toDataURL('image/png');
    return screenshot;
}
// @ts-ignore
window.loadSTLAndTakeScreenshot = loadSTLAndTakeScreenshot;
