import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { API_URL } from "./env";
import { enqueueSnackbar } from "notistack";
import { Button, Typography } from "@mui/material";
export class APIError extends Error {
    constructor(code, message, details) {
        super(message);
        this.name = 'APIError';
        this.code = code;
        this.details = details;
    }
    static fromJSONResponse(data) {
        return new APIError(data['code'], data['message'], data['details']);
    }
    toJSON() {
        return {
            code: this.code,
            message: this.message,
            details: this.details
        };
    }
    toString() {
        return `${this.code}: ${this.message}\n${JSON.stringify(this.details, null, 2)}`;
    }
    [Symbol.toPrimitive](hint) {
        if (hint === 'number') {
            return 1;
        }
        return this.toString();
    }
}
const defaultErrorSnackbarOptions = {
    variant: 'error',
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    autoHideDuration: 10000,
};
export function presentErrorSnackBar(e) {
    const shouldShowDetails = e instanceof APIError;
    function createDetailsButton() {
        return (_jsx(Button, { color: "inherit", variant: "outlined", onClick: () => {
                enqueueSnackbar(_jsxs("div", { children: [_jsx(Typography, { children: e.message }), 'code' in e && _jsx("pre", { children: e.code }), 'details' in e && _jsx("pre", { style: { maxHeight: 350, overflow: 'auto' }, children: typeof e.details === 'string' ? e.details : JSON.stringify(e.details, null, 2) })] }), {
                    ...defaultErrorSnackbarOptions,
                });
            }, children: "Show Details" }));
    }
    enqueueSnackbar(e.message, {
        ...defaultErrorSnackbarOptions,
        action: shouldShowDetails ? createDetailsButton() : undefined
    });
}
export async function apiFetch(path, options = {}) {
    try {
        const response = await fetch(`${API_URL}${path}`, options);
        if (!response.ok) {
            throw APIError.fromJSONResponse(await response.json());
        }
        return response;
    }
    catch (e) {
        presentErrorSnackBar(e);
        throw e;
    }
}
