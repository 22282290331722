import { useLayoutEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
export function useElementSize() {
    const [target, setTarget] = useState(null);
    const [size, setSize] = useState({
        width: 0,
        height: 0
    });
    const setRoundedSize = ({ width, height }) => {
        setSize({ width: Math.round(width), height: Math.round(height) });
    };
    useLayoutEffect(() => {
        target && setRoundedSize(target.getBoundingClientRect());
    }, [target]);
    useResizeObserver(target, (entry) => {
        const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
        setRoundedSize({ width, height });
    });
    // use a setState + ref because just ref would not re-render when the element would re-appear in the DOM
    const setTargetWithRef = setTarget;
    setTargetWithRef.current = target;
    return [setTargetWithRef, size];
}
