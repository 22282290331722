var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { DataFrame } from "../util/dataframe";
import { computed, makeObservable, observable } from "mobx";
import { createContext } from "../util/context";
import { userStore } from "./LoginStore";
import { apiFetch } from "../util/api";
class ScadDocumentDataFrame extends DataFrame {
    constructor(documentId) {
        super({ autoFetch: !!documentId });
        this.destructors = [];
        this.deinit = () => this.destructors.forEach(x => x());
        this.markRender = async () => {
            await userStore.authenticatedFetch(`/scad/${this.documentId}/render`, { method: 'POST' });
        };
        this.documentId = documentId;
        makeObservable(this);
        // @ts-ignore
        window.scadDocument = this;
        if (this.documentId) {
            this.update().catch(console.error);
        }
    }
    get sanitizedNameForFile() {
        return this.data?.name.replace(/[^a-z0-9\._\-'"`]+/gi, '_').toLowerCase();
    }
    fetch(abortSignal) {
        return apiFetch(`/scad/${this.documentId}`, { signal: abortSignal })
            .then(x => x.json())
            .then(x => x.data);
    }
}
__decorate([
    observable
], ScadDocumentDataFrame.prototype, "documentId", void 0);
__decorate([
    computed
], ScadDocumentDataFrame.prototype, "sanitizedNameForFile", null);
export const { useStore: useScadDocument, Provider: ScadDocumentProvider, } = createContext(ScadDocumentDataFrame, {
    dispose: s => s.deinit()
});
