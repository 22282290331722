var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { DataFrame } from "../util/dataframe";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { decodeJwt } from "jose";
import { debounce } from "@mui/material";
import { apiFetch } from "../util/api";
class UserStore extends DataFrame {
    get tokenData() {
        if (!this.authTokenJWT) {
            return null;
        }
        return decodeJwt(this.authTokenJWT);
    }
    get isLoggerIn() {
        return this.tokenData && ((this.tokenData.exp ?? (Date.now() + 1) / 1000)) * 1000 > Date.now();
    }
    constructor() {
        super();
        this.authTokenJWT = null;
        this.loginStart = async (email) => {
            const r = await apiFetch(`/user/login/begin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });
            const { data: { token } } = await r.json();
            return token;
        };
        this.loginComplete = async (challengeToken, code) => {
            const r = await apiFetch(`/user/login/complete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: challengeToken, code })
            });
            const { data: { token } } = await r.json();
            runInAction(() => this.authTokenJWT = token);
            return token;
        };
        this.checkAndRefreshToken = async () => {
            if (!this.tokenData) {
                return;
            }
            if (this.tokenData.exp && this.tokenData.exp * 1000 > Date.now() - 1000 * 60 * 60 * 24) {
                return;
            }
            console.log('Refreshing auth token');
            const r = await this.authenticatedFetch(`/user/refresh`, {
                method: 'POST'
            });
            if (!r.ok) {
                throw new Error(await r.text());
            }
            const { data: { token } } = await r.json();
            runInAction(() => this.authTokenJWT = token);
        };
        this.logout = () => {
            this.authTokenJWT = null;
        };
        this.updatePreferences = debounce(async (preferences) => {
            await this.authenticatedFetch(`/user/preference`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(preferences)
            });
        }, 1000);
        this.setBuildVolume = (w, l, h) => {
            if (!this.data)
                return;
            this.data.preferences.printerSize = { w, l, h };
            this.updatePreferences({ printerSize: { w, l, h } });
        };
        makeObservable(this);
        this.authTokenJWT = localStorage.getItem('authTokenJWT') || null;
        reaction(() => this.authTokenJWT, () => {
            if (this.authTokenJWT) {
                localStorage.setItem('authTokenJWT', this.authTokenJWT);
                this.update().catch(console.error);
            }
            else {
                localStorage.removeItem('authTokenJWT');
            }
        });
        if (this.isLoggerIn) {
            this.update().catch(console.error);
        }
    }
    async fetch(abortSignal) {
        const r = await this.authenticatedFetch(`/user/me`, { signal: abortSignal });
        if (!r.ok) {
            throw new Error(await r.text());
        }
        const { data } = await r.json();
        return data;
    }
    async authenticatedFetch(path, init) {
        if (!this.authTokenJWT) {
            throw new Error('Not logged in');
        }
        if (!init) {
            init = {};
        }
        if (!init.headers) {
            init.headers = {};
        }
        if (init.headers instanceof Headers) {
            init.headers.set('Authorization', `Bearer ${this.authTokenJWT}`);
        }
        else if (Array.isArray(init.headers)) {
            init.headers.push(['Authorization', `Bearer ${this.authTokenJWT}`]);
        }
        else {
            init.headers['Authorization'] = `Bearer ${this.authTokenJWT}`;
        }
        await this.checkAndRefreshToken();
        return await apiFetch(path, init);
    }
    get buildVolume() {
        return this.data?.preferences?.printerSize || { w: 210, l: 220, h: 250 };
    }
}
__decorate([
    observable
], UserStore.prototype, "authTokenJWT", void 0);
__decorate([
    computed
], UserStore.prototype, "tokenData", null);
__decorate([
    computed
], UserStore.prototype, "isLoggerIn", null);
__decorate([
    action
], UserStore.prototype, "logout", void 0);
__decorate([
    computed
], UserStore.prototype, "buildVolume", null);
__decorate([
    action
], UserStore.prototype, "setBuildVolume", void 0);
export const userStore = new UserStore();
// @ts-ignore
window.userStore = userStore;
