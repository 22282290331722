import { jsx as _jsx } from "react/jsx-runtime";
import { useModel } from "../../../stores/model";
import { STLViewer } from "../STLViewer/STLViewer";
import { observer } from "mobx-react-lite";
import css from './Editor.module.scss';
import { forwardRef } from "react";
import { userStore } from "../../../stores/LoginStore";
import { SideBySide } from "../../atoms/SideBySide/SideBySide";
export const EditorPanelWithSTLViewer = observer(forwardRef((props, ref) => {
    const model = useModel();
    const buildVolume = userStore.buildVolume ?? { w: 256, l: 256, h: 256 };
    const stlViewerComponent = _jsx(STLViewer, { fileUrl: model.output?.outFileURL, className: css.stlViewer, buildPlateSize: { gridWidth: buildVolume.w, gridLength: buildVolume.l }, centerModelOnBuildPlate: true, interactive: true, onReady: (model, camera, screenshot) => {
            if (typeof ref === 'function') {
                ref({ camera, model, screenshot });
            }
            else if (ref) {
                ref.current = { camera, model, screenshot };
            }
            // move camera to a better position
            camera.setCameraPosition({
                latitude: 25 * Math.PI / 180,
                longitude: (90 + 45) * Math.PI / 180,
                distance: 1,
            });
        } });
    return (_jsx(SideBySide, { defaultSplit: props.defaultSplit, leftSide: props.children, leftPanelClassName: props.panelClassName, rightSide: stlViewerComponent, rightPanelClassName: css.stlViewer }));
}));
