import { jsx as _jsx } from "react/jsx-runtime";
import { JSONEditor } from "vanilla-jsoneditor";
import { memo, useEffect, useRef } from "react";
import css from "./JsonEditor.module.scss";
import classNames from "classnames";
export const SvelteJSONEditor = memo(function SvelteJSONEditor(props) {
    const refContainer = useRef(null);
    const refEditor = useRef(null);
    useEffect(() => {
        // create editor
        console.log("create editor", refContainer.current);
        refEditor.current = new JSONEditor({
            target: refContainer.current,
            props,
        });
        return () => {
            // destroy editor
            if (refEditor.current) {
                console.log("destroy editor");
                refEditor.current.destroy();
                refEditor.current = null;
            }
        };
    }, []);
    // update props
    // useEffect(() => {
    //   if (refEditor.current) {
    //     console.log("update props", props);
    //     refEditor.current.updateProps(props);
    //   }
    // }, [props]);
    return _jsx("div", { className: classNames(css.vanillaJsoneditorReact, 'jse-theme-dark'), ref: refContainer });
});
