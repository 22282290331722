import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import css from "./SideBySide.module.scss";
import classNames from "classnames";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useElementSize } from "../../../util/hooks";
import { preventEvent } from "../../../util/react";
export const SideBySide = (props) => {
    const defaultSplit = props.defaultSplit ?? 0.5;
    const [boxRef, { width, height }] = useElementSize();
    const [leftWidth, setLeftWidth] = useState(defaultSplit);
    const [draggingSeparator, setDraggingSeparator] = useState(false);
    const [mouseStartX, setMouseStartX] = useState(0);
    const [oldWidth, setOldWidth] = useState(0);
    // effect to handle initial resize of the left panel
    useEffect(() => {
        if (leftWidth <= 1 && leftWidth >= 0) {
            setLeftWidth(defaultSplit * width);
        }
        if (leftWidth >= -1 && leftWidth <= 0) {
            setLeftWidth((1 + defaultSplit) * width);
        }
        if (leftWidth < -1) {
            setLeftWidth(width + leftWidth);
        }
    }, [width]);
    const mouseOut = useCallback(function mouseOut() {
        setDraggingSeparator(false);
    }, [setDraggingSeparator]);
    const mouseDown = useCallback(function mouseDown(ev) {
        const clientX = 'clientX' in ev ? ev.clientX : ev.touches[0].clientX;
        setDraggingSeparator(true);
        setMouseStartX(clientX);
        setOldWidth(leftWidth);
    }, [leftWidth, setOldWidth, setDraggingSeparator, setMouseStartX]);
    const mouseMove = useCallback(function mouseMove(ev) {
        const clientX = 'clientX' in ev ? ev.clientX : ev.touches[0].clientX;
        if (draggingSeparator) {
            let newLeftWidth = clientX - mouseStartX + oldWidth;
            if (newLeftWidth <= 10) {
                newLeftWidth = 0;
            }
            if (newLeftWidth >= width - 10) {
                newLeftWidth = width;
            }
            setLeftWidth(newLeftWidth);
        }
    }, [draggingSeparator, oldWidth, mouseStartX, setLeftWidth]);
    // add mouse leave event listener to window while component is mounted
    useEffect(() => {
        window.addEventListener('mouseleave', mouseOut);
        window.addEventListener('mouseup', mouseOut);
        window.addEventListener('touchend', mouseOut);
        window.addEventListener('dragend', mouseOut);
        return () => {
            window.removeEventListener('mouseleave', mouseOut);
            window.removeEventListener('mouseup', mouseOut);
            window.removeEventListener('touchend', mouseOut);
            window.removeEventListener('dragend', mouseOut);
        };
    }, []);
    return (_jsxs("div", { className: classNames(css.root, props.className), ref: boxRef, onDragStart: preventEvent, children: [_jsx("div", { className: classNames(css.leftSide, props.leftPanelClassName), style: { width: leftWidth }, children: props.leftSide }), _jsx("div", { className: css.divider, onMouseDown: mouseDown, onTouchStart: mouseDown, onMouseMove: mouseMove, onTouchMove: mouseMove, children: _jsx(DragIndicatorIcon, {}) }), _jsx("div", { className: classNames(css.rightSide, props.rightPanelClassName), style: { width: `calc(100% - ${leftWidth}px)` }, children: props.rightSide })] }));
};
