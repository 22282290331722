const NUMBER_REGEX = /^-?(?:\d+|\d*\.\d+)$/;
const VECTOR_REGEX = /^\s*\[\s*(?:-?(?:\d+|\d*\.\d+)(?:\s*,\s*-?(?:\d+|\d*\.\d+))*)?\s*\]\s*$/;
function parseValue(valueStr) {
    if (VECTOR_REGEX.test(valueStr)) {
        const vectorValues = valueStr.substring(1, valueStr.length - 1)
            .split(',')
            .map(v => parseValue(v.trim()));
        const allValuesAreNumbers = vectorValues.every(([_, type]) => type === 'number');
        if (allValuesAreNumbers) {
            console.log('parsed', valueStr, 'as vector');
            return [
                vectorValues.map(([v]) => v),
                'vector'
            ];
        }
        console.log('parsed', valueStr, 'as expression');
        return [
            valueStr,
            'expression'
        ];
    }
    if (valueStr === 'true' || valueStr === 'false') {
        console.log('parsed', valueStr, 'as bool');
        return [valueStr === 'true', 'boolean'];
    }
    if (NUMBER_REGEX.test(valueStr)) {
        console.log('parsed', valueStr, 'as number');
        return [parseFloat(valueStr), 'number'];
    }
    if (valueStr.match(/^['"].*['"]$/)) {
        console.log('parsed', valueStr, 'as string');
        return [valueStr.replace(/["']/g, ''), 'string'];
    }
    if (valueStr === 'undef') {
        console.log('parsed', valueStr, 'as undef');
        return [undefined, 'undefined'];
    }
    console.log('parsed', valueStr, 'as expression');
    return [valueStr, 'expression'];
}
export function parseScadVariables(content) {
    const lines = content.split('\n');
    const variables = [{
            variables: [],
            sectionName: 'Default Parameters'
        }];
    let accumulatedComment = [];
    for (const line of lines) {
        const trimmedLine = line.trim();
        // check for new section
        const sectionRegex = /^\s*\/\*\s*\[(.*?)\]\s*\*\//;
        const sectionMatch = trimmedLine.match(sectionRegex);
        if (sectionMatch) {
            variables.push({
                sectionName: sectionMatch[1].trim(),
                variables: []
            });
            // accumulatedComment = [];// Reset accumulatedComment for the new section
            // found a new section, continue to the next line
            continue;
        }
        const currentSection = variables[variables.length - 1];
        // check for new comment line
        const commentRegex = /^\s*(?:\/\/|\/\*)(.*?)(?:\*\/)?$/;
        const commentMatch = trimmedLine.match(commentRegex);
        if (commentMatch) {
            accumulatedComment.push(commentMatch[1].trim());
            // found a comment, continue to the next line
            continue;
        }
        // check if we set a variable
        const variableRegex = /^\s*([a-zA-Z_0-9]*)\s*=\s*(.*?);/;
        const variableMatch = trimmedLine.match(variableRegex);
        if (variableMatch) {
            const varName = variableMatch[1];
            const valueStr = variableMatch[2];
            const [value, varType] = parseValue(valueStr.trim());
            if (varType === 'expression') {
                console.log(`Skipping expression: ${valueStr}`);
                continue;
            }
            if (varType === 'undefined') {
                console.log(`Skipping undefined: ${varName}`);
                continue;
            }
            // Create the variable object and assign basic properties
            const currentVariable = {
                varName: varName,
                prettyName: varName,
                type: varType,
                value: value,
                default: value,
                desc: accumulatedComment.join('\n')
            };
            currentSection.variables.push(currentVariable);
            // we've consumed the comments in the current variable, so we can reset it
            accumulatedComment = [];
            // Parse range and choices
            const rangeCommentMatch = line.match(/\/\/\s*\[(.*)\]/);
            if (rangeCommentMatch) {
                const [, insideBrackets] = rangeCommentMatch;
                const choices = insideBrackets.split(',');
                if (currentVariable.type === 'number' || currentVariable.type === 'vector') {
                    if (choices.length === 1 && choices[0].indexOf(':') !== -1) {
                        const range = choices[0].split(':');
                        if (range.length === 2) {
                            currentVariable.range = {
                                min: parseFloat(range[0]),
                                max: parseFloat(range[1])
                            };
                            continue;
                        }
                        else if (range.length === 3) {
                            currentVariable.range = {
                                min: parseFloat(range[0]),
                                step: parseFloat(range[1]),
                                max: parseFloat(range[2]),
                            };
                            continue;
                        }
                    }
                }
                if (currentVariable.type === 'number') {
                    currentVariable.choices = choices.map((choice) => {
                        let [val, desc = ''] = choice.split(':');
                        return { val: parseFloat(val), desc };
                    });
                }
                else if (currentVariable.type === 'boolean') {
                    currentVariable.choices = choices.map((choice) => {
                        let [val, desc = ''] = choice.split(':');
                        return { val: val === 'true', desc };
                    });
                }
                else if (currentVariable.type === 'vector') {
                    // l=[15,14]; //[[15,14]:test,[17,17]]
                    // will have been split as ['[15', '14]:test', '[17', '17]']
                    // we have to re-construct the pieces of the vector
                    // we have to take care of badly formatted commends like
                    // knurlSize=[1.25,1.25];//[1.25,2]
                    // this is probably a value update and was not mean to describe a choice
                    let fixedChoices;
                    try {
                        const vectors = [];
                        for (const chunk of choices) {
                            if (chunk.trim().startsWith('[')) {
                                vectors.push([chunk.trim()]);
                            }
                            else {
                                vectors[vectors.length - 1].push(chunk.trim());
                            }
                        }
                        fixedChoices = vectors.map((vector) => vector.join(','));
                    }
                    catch (e) {
                        console.error(`Failed to fix choices: ${choices}`, e);
                        fixedChoices = [];
                    }
                    currentVariable.choices = fixedChoices.map((choice) => {
                        let [val, desc = ''] = choice.split(':');
                        return { val: val.replace(/[\[\]]/g, '').split(',').map(v => parseFloat(v)), desc };
                    });
                }
                else if (currentVariable.type === 'string') {
                    currentVariable.choices = choices.map((choice) => {
                        let [val, desc = ''] = choice.split(':');
                        return { val: val.trim().replace(/["']/g, ''), desc };
                    });
                }
                else {
                    console.log(`Skipping choices for ${varName}:${varType} = ${choices}`);
                }
            }
        }
        else {
            //   we didn't have a new variable and by this point
            //   we know it's not a comment
            //   so it much be an expression or something else
            //   meaning that we can reset the comments
            accumulatedComment = [];
        }
    }
    return variables.filter(x => x.variables.length > 0);
}
