import { jsx as _jsx } from "react/jsx-runtime";
import { CircularProgress } from "@mui/material";
export function FullPageCircularLoader(props) {
    return (_jsx("div", { style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
        }, children: _jsx(CircularProgress, { size: 64, ...props }) }));
}
