// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L44XoxNc2AZOfhPG7UXm{display:flex;flex-direction:column;padding:16px;box-sizing:border-box}.L44XoxNc2AZOfhPG7UXm>*{margin-bottom:16px !important}.L44XoxNc2AZOfhPG7UXm>*:last-child{margin-bottom:0}.L44XoxNc2AZOfhPG7UXm .dgt4ZcMKTnLATVtgAoNg{display:flex;flex-direction:column;justify-content:stretch;align-items:stretch}.L44XoxNc2AZOfhPG7UXm .dgt4ZcMKTnLATVtgAoNg>*{flex-grow:1;flex-shrink:0}.L44XoxNc2AZOfhPG7UXm .dgt4ZcMKTnLATVtgAoNg .rsw-html{color:var(--color-text-primary)}.eCm_PuIp4uknytby15Bk{display:flex;flex-direction:column;width:100%;height:100%;justify-content:center;align-items:center}.eCm_PuIp4uknytby15Bk svg{font-size:5em}.eCm_PuIp4uknytby15Bk button{margin-right:16px}.eCm_PuIp4uknytby15Bk button:last-child{margin-right:0}`, "",{"version":3,"sources":["webpack://./src/pages/CreateNewModel/CreateNewModel.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CAEA,wBACE,6BAAA,CAEA,mCACE,eAAA,CAIJ,4CAGE,YAAA,CACA,qBAAA,CACA,uBAAA,CACA,mBAAA,CAEA,8CACE,WAAA,CACA,aAAA,CAGF,sDACE,+BAAA,CAKN,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,WAAA,CAEA,sBAAA,CACA,kBAAA,CAEA,0BACE,aAAA,CAGF,6BACE,iBAAA,CACA,wCACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepDetails": `L44XoxNc2AZOfhPG7UXm`,
	"descriptionContainer": `dgt4ZcMKTnLATVtgAoNg`,
	"submitted": `eCm_PuIp4uknytby15Bk`
};
export default ___CSS_LOADER_EXPORT___;
