export const Licenses = [
    { name: "Creative Commons — Public Domain", code: "CC0 1.0" },
    { name: "Creative Commons — Attribution", code: "CC BY 4.0" },
    { name: "Creative Commons — Attribution — Share Alike", code: "CC BY-SA 4.0" },
    { name: "Creative Commons — Attribution — NoDerivatives", code: "CC BY-ND 4.0" },
    { name: "Creative Commons — Attribution — Noncommercial", code: "CC BY-NC 4.0" },
    { name: "Creative Commons — Attribution — Noncommercial — Share Alike", code: "CC BY-NC-SA 4.0" },
    { name: "Creative Commons — Attribution — Noncommercial — NoDerivatives", code: "CC BY-NC-ND 4.0" },
    { name: "GNU General Public License v2.0", code: "GPLv2" },
    { name: "GNU General Public License v3.0", code: "GPLv3" }
];
