import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import css from "./Main.module.scss";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { Avatar, Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { userStore } from "../../stores/LoginStore";
import PlusIcon from '@mui/icons-material/Add';
import { useState } from "react";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import XIcon from '@mui/icons-material/X';
import GitHubIcon from '@mui/icons-material/GitHub';
export const MainPage = observer(function MainPage() {
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const avatar = _jsx(Avatar, { children: userStore.data?.username[0].toUpperCase() });
    const about = (_jsxs(Typography, { style: { whiteSpace: 'wrap', fontFamily: 'monospace' }, children: ["This website is a hobby of mine and is not affiliated with OpenSCAD.", "\n", "It is a free and open-source project.", "\n", "If you have any questions or suggestions, please feel free to contact me:", "\n", _jsx("br", {}), _jsx(Button, { onClick: () => {
                    window.open('https://x.com/legraphista', '_blank');
                }, style: { fontFamily: 'monospace' }, startIcon: _jsx(XIcon, {}), children: "@legraphista" }), _jsx(Button, { onClick: () => {
                    window.open('https://github.com/legraphista', '_blank');
                }, style: { fontFamily: 'monospace' }, startIcon: _jsx(GitHubIcon, {}), children: "/legraphista" })] }));
    return (_jsxs("div", { className: css.root, children: [_jsxs("header", { children: [_jsx(Tooltip, { title: about, children: _jsxs("div", { className: css.banner, onClick: () => nav('/'), children: [_jsx("img", { className: css.logo, src: "/logo512.png" }), _jsxs(Typography, { variant: "h5", fontWeight: "bold", noWrap: true, children: ["ScadHub ", _jsx("sup", { children: _jsx("i", { children: "(beta)" }) })] })] }) }), _jsxs("div", { className: css.search, children: [_jsx(SearchIcon, {}), _jsx("input", { type: "text", placeholder: "Search", className: css.search, value: searchParams.get('q') ?? '', onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        nav('/?' + new URLSearchParams(searchParams).toString());
                                    }
                                }, onChange: (e) => {
                                    setSearchParams({ q: e.target.value });
                                } })] }), _jsx("div", { className: css.divider }), !userStore.isLoggerIn && (_jsx(_Fragment, { children: _jsx(Button, { onClick: () => {
                                nav('/login');
                            }, variant: "outlined", size: "small", children: "Login" }) })), userStore.isLoggerIn && (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: () => {
                                    nav('/create');
                                }, variant: "outlined", className: css.addNewBtn, size: "small", children: _jsx(PlusIcon, {}) }), _jsx(IconButton, { onClick: (e) => setMenuAnchorEl(e.currentTarget), children: avatar }), _jsxs(Menu, { anchorEl: menuAnchorEl, id: "account-menu", open: !!menuAnchorEl, onClose: () => setMenuAnchorEl(null), onClick: () => setMenuAnchorEl(null), transformOrigin: { horizontal: 'right', vertical: 'top' }, anchorOrigin: { horizontal: 'right', vertical: 'bottom' }, children: [_jsxs(MenuItem, { disabled: true, children: [avatar, "\u00A0\u00A0Profile"] }), _jsx(Divider, {}), _jsxs(MenuItem, { disabled: true, children: [_jsx(ListItemIcon, { children: _jsx(Settings, { fontSize: "small" }) }), "Settings"] }), _jsxs(MenuItem, { onClick: userStore.logout, children: [_jsx(ListItemIcon, { children: _jsx(Logout, { fontSize: "small" }) }), "Logout"] })] })] }))] }), _jsx(Outlet, {})] }));
});
