var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, makeObservable, observable } from "mobx";
export class DataFrame {
    constructor(options = {}) {
        this.fetching = false;
        this.data = null;
        this.error = null;
        this.keepPreviousDataWhiteFetching = true;
        this.lastUpdateRequest = 0;
        this.promise = null;
        this.internalFetch = async (abortSignal) => {
            this.setFetching(true);
            const myFetchId = ++this.lastUpdateRequest;
            abortSignal = abortSignal || new AbortController().signal;
            try {
                if (!this.keepPreviousDataWhiteFetching) {
                    this.setData(null);
                }
                this.setError(null);
                const rawData = await this.fetch(abortSignal);
                if (this.lastUpdateRequest !== myFetchId) {
                    return console.warn(`Preventing DataFrame ${this.constructor.name} from manifesting outdated results`);
                }
                this.setData(this.postProcess(rawData));
            }
            catch (e) {
                this.setError(e);
                this.setData(null);
            }
            finally {
                this.setFetching(false);
                this.promise = null;
            }
        };
        this.update = (abortSignal) => this.get(true, abortSignal);
        makeObservable(this);
        if (options.autoFetch) {
            // schedule for next tick such that the class is fully initialized by any subclass
            setTimeout(() => {
                this.get().catch(action(e => this.error = e));
            }, 0);
        }
    }
    postProcess(fetchedData) {
        return fetchedData;
    }
    setData(data) {
        this.data = data;
    }
    setError(error) {
        this.error = error;
    }
    setFetching(fetching) {
        this.fetching = fetching;
    }
    async get(update = false, abortSignal) {
        if (update || (!this.data && !this.fetching)) {
            this.promise = this.internalFetch(abortSignal);
        }
        await this.promise;
        if (this.error) {
            throw this.error;
        }
        return this.data;
    }
    read() {
        if (this.promise) {
            throw this.promise;
        }
        if (this.error) {
            throw this.error;
        }
        return this.data;
    }
    async populate() {
        await this.get();
        return this;
    }
}
__decorate([
    observable
], DataFrame.prototype, "fetching", void 0);
__decorate([
    observable
], DataFrame.prototype, "data", void 0);
__decorate([
    observable.ref
], DataFrame.prototype, "error", void 0);
__decorate([
    observable.ref
], DataFrame.prototype, "promise", void 0);
__decorate([
    action
], DataFrame.prototype, "setData", null);
__decorate([
    action
], DataFrame.prototype, "setError", null);
__decorate([
    action
], DataFrame.prototype, "setFetching", null);
