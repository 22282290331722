// Portions of this file are Copyright 2021 Google LLC, and licensed under GPL2+. See COPYING.
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { checkSyntax, render } from "../openscad/runner/actions";
import { downloadUrl, formatBytes, formatMillis, promisifyDelayableExecution } from '../openscad/utils';
import { action, computed, makeObservable, observable, runInAction, toJS } from "mobx";
import { createContext } from "../util/context";
import { fsSingleton } from "./fs";
import { debounce } from "@mui/material";
export class Model {
    get activeFile() {
        return this.params.sources.find(src => src.path === this.params.activePath);
    }
    constructor(initialFile = '') {
        this.params = {
            activePath: '/playground.scad',
            sources: [{ path: '/playground.scad', content: '' }],
            features: [
                'manifold',
                'fast-csg',
                // lazy union break some models
                // 'lazy-union',
                // those aren't recognized
                // 'assimp',
                // 'render-colors',
                // 'render-modifiers'
            ],
            renderFormat: 'stl', //'glb',
            exportFormat: 'stl', //'glb',
            vars: {},
        };
        this.output = null;
        this.export = null;
        this.logStream = [];
        this.lastCheckerRun = null;
        this.rendering = false;
        this.previewing = false;
        this.exporting = false;
        this.checkingSyntax = false;
        this.parameterSet = null;
        this.error = '';
        this.destructors = [];
        this.renderAsyncDebounced = debounce(this.renderAsync, 500);
        this.reset = () => {
            this.params = {
                activePath: '/playground.scad',
                sources: [{ path: '/playground.scad', content: '' }],
                features: [
                    'manifold',
                    'fast-csg',
                ],
                renderFormat: 'stl',
                exportFormat: 'stl',
                vars: {},
            };
            this.output = null;
            this.export = null;
            this.logStream = [];
            this.lastCheckerRun = null;
            this.rendering = false;
            this.previewing = false;
            this.exporting = false;
            this.checkingSyntax = false;
            this.parameterSet = null;
            this.error = '';
        };
        makeObservable(this);
        // @ts-ignore
        window.model = this;
        this.activeFile.content = initialFile;
        // this.destructors.push(reaction(() => this.params.vars, () => {
        //   console.log('vars changed')
        //   this.processSourceAsync().catch(console.error);
        // }));
    }
    async init() {
        if (!this.output &&
            !this.lastCheckerRun &&
            !this.previewing &&
            !this.checkingSyntax &&
            !this.rendering &&
            this.source.trim()) {
            await this.processSourceAsync();
        }
    }
    deinit() {
        this.destructors.forEach(d => d());
    }
    setRenderFormat(format) {
        this.params.renderFormat = format;
    }
    setExportFormat(format) {
        this.params.exportFormat = format;
    }
    setVar(name, value) {
        this.params.vars[name] = value;
        // fixme this should trigger on deepObserve of params
        this.renderAsyncDebounced({ isPreview: true, now: false });
    }
    setPresets(presets) {
        this.params.vars = presets;
        // fixme this should trigger on deepObserve of params
        this.renderAsync({ isPreview: true, now: false }).catch(console.error);
    }
    openFile(path) {
        // alert(`TODO: open ${path}`);
        if (path === this.params.activePath) {
            return;
        }
        this.params.activePath = path;
        if (!this.params.sources.find(src => src.path === path)) {
            let content = '';
            try {
                if (!fsSingleton) {
                    throw new Error('FS not initialized');
                }
                content = new TextDecoder("utf-8").decode(fsSingleton.readFileSync(path));
            }
            catch (e) {
                console.error('Error while reading file:', e);
            }
            this.params.sources.push({ path, content });
            this.lastCheckerRun = null;
            this.output = null;
        }
        this.processSourceAsync().catch(console.error);
    }
    get source() {
        return this.params.sources.find(src => src.path === this.params.activePath)?.content ?? '';
    }
    setSource(source) {
        const file = this.params.sources.find(src => src.path === this.params.activePath);
        if (!file) {
            throw new Error('Active path not found in sources!');
        }
        file.content = source;
        this.processSourceAsync().catch(console.error);
    }
    async processSourceAsync() {
        // const params = this.state.params;
        // if (isFileWritable(params.sourcePath)) {
        // const absolutePath = params.sourcePath.startsWith('/') ? params.sourcePath : `/${params.sourcePath}`;
        // this.fs.writeFile(params.sourcePath, params.source);
        // }
        await Promise.all([
            this.checkSyntaxAsync(),
            this.renderAsync({ isPreview: true, now: false })
        ]);
    }
    async checkSyntaxAsync() {
        runInAction(() => this.checkingSyntax = true);
        const { activePath, sources } = toJS(this.params);
        const promisifiedCheckSyntax = promisifyDelayableExecution(checkSyntax(activePath, sources));
        try {
            const output = await promisifiedCheckSyntax({ now: false });
            runInAction(() => {
                this.lastCheckerRun = output;
                this.parameterSet = output?.parameterSet ?? null;
            });
        }
        catch (e) {
            runInAction(() => {
                this.error = String(e);
            });
        }
        finally {
            runInAction(() => {
                this.checkingSyntax = false;
            });
        }
    }
    async exportAsync(name) {
        if (this.output && (this.params.renderFormat === this.params.exportFormat)) {
            downloadUrl(this.output.outFileURL, name || this.output.outFile.name);
            return;
        }
        if (this.params.exportFormat === '3mf') {
            throw new Error('3mf export not supported yet');
        }
        // if (this.params.exportFormat == '3mf' && (this.view.extruderPicker || (this.params.extruderColors ?? []).length === 0)) {
        //   this.mutate(s => this.state.view.extruderPicker = true);
        //   return;
        // }
        runInAction(() => {
            this.exporting = true;
        });
        const outFile = this.output?.outFile;
        const outFileURL = this.output?.outFileURL;
        if (!outFile || !outFileURL) {
            throw new Error('No output file to export');
        }
        const scadPath = '/export.scad';
        const sources = [
            {
                path: scadPath,
                content: `import("${outFile.name}");`
            },
            {
                path: outFile.name,
                url: outFileURL,
            }
        ];
        const { features, exportFormat, extruderColors } = toJS(this.params);
        const promisifiedRender = promisifyDelayableExecution(render({ scadPath, sources, features, extraArgs: [], isPreview: false, renderFormat: exportFormat, extruderColors }));
        try {
            const output = await promisifiedRender({ now: true });
            runInAction(() => {
                if (this.export?.outFileURL) {
                    URL.revokeObjectURL(this.export.outFileURL);
                }
                this.export = {
                    outFile: output.outFile,
                    outFileURL: URL.createObjectURL(output.outFile),
                    elapsedMillis: output.elapsedMillis,
                    formattedElapsedMillis: formatMillis(output.elapsedMillis),
                    formattedOutFileSize: formatBytes(output.outFile.size),
                };
                downloadUrl(this.export.outFileURL, name || output.outFile.name);
            });
        }
        catch (e) {
            runInAction(() => {
                this.error = String(e);
            });
        }
        finally {
            runInAction(() => {
                this.exporting = false;
            });
        }
    }
    async renderAsync({ isPreview, now }) {
        const setRendering = action((value) => {
            if (isPreview) {
                this.previewing = value;
                this.rendering = false;
            }
            else {
                this.rendering = value;
                this.previewing = false;
            }
        });
        setRendering(true);
        const { activePath, sources, vars, features, renderFormat, extruderColors } = toJS(this.params);
        const varsCopy = { ...vars };
        if (isPreview) {
            varsCopy['$preview'] = true;
            varsCopy['$fn'] = '$fn' in varsCopy
                ? varsCopy['$fn'] / 10
                : (this.parameterSet?.parameters?.find(p => p.name === '$fn')?.initial ?? 100) / 10;
        }
        runInAction(() => {
            this.logStream.replace([]);
        });
        const promisifiedRender = promisifyDelayableExecution(render({
            scadPath: activePath,
            sources,
            vars: varsCopy,
            features,
            extraArgs: [],
            isPreview,
            renderFormat,
            extruderColors,
            pipeCb: action((pipe, message) => {
                this.logStream.push([pipe, message]);
            })
        }));
        try {
            const output = await promisifiedRender({ now });
            runInAction(() => {
                this.error = '';
                this.lastCheckerRun = {
                    logText: output.logText,
                    markers: output.markers,
                };
                if (this.output?.outFileURL) {
                    URL.revokeObjectURL(this.output.outFileURL);
                }
                this.output = {
                    isPreview: isPreview,
                    outFile: output.outFile,
                    outFileURL: URL.createObjectURL(output.outFile),
                    elapsedMillis: output.elapsedMillis,
                    formattedElapsedMillis: formatMillis(output.elapsedMillis),
                    formattedOutFileSize: formatBytes(output.outFile.size),
                };
            });
        }
        catch (e) {
            console.error(e);
            runInAction(() => this.error = String(e));
            throw e;
        }
        finally {
            setRendering(false);
        }
    }
}
__decorate([
    observable
], Model.prototype, "params", void 0);
__decorate([
    observable
], Model.prototype, "output", void 0);
__decorate([
    observable
], Model.prototype, "export", void 0);
__decorate([
    observable
], Model.prototype, "logStream", void 0);
__decorate([
    observable
], Model.prototype, "lastCheckerRun", void 0);
__decorate([
    observable
], Model.prototype, "rendering", void 0);
__decorate([
    observable
], Model.prototype, "previewing", void 0);
__decorate([
    observable
], Model.prototype, "exporting", void 0);
__decorate([
    observable
], Model.prototype, "checkingSyntax", void 0);
__decorate([
    observable
], Model.prototype, "parameterSet", void 0);
__decorate([
    observable
], Model.prototype, "error", void 0);
__decorate([
    computed
], Model.prototype, "activeFile", null);
__decorate([
    action
], Model.prototype, "setRenderFormat", null);
__decorate([
    action
], Model.prototype, "setExportFormat", null);
__decorate([
    action
], Model.prototype, "setVar", null);
__decorate([
    action
], Model.prototype, "setPresets", null);
__decorate([
    action
], Model.prototype, "openFile", null);
__decorate([
    computed
], Model.prototype, "source", null);
__decorate([
    action
], Model.prototype, "setSource", null);
__decorate([
    action
], Model.prototype, "reset", void 0);
export const { useStore: useModel, Provider: ModelProvider } = createContext(Model, {
    init: s => s.init().catch(console.error),
    dispose: s => s.deinit(),
});
