import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import schema from './schema.json';
import { SvelteJSONEditor } from "../../JsonEditor/JsonEditor";
import { createAjvValidator } from "vanilla-jsoneditor";
export const ScadParameterValidator = createAjvValidator({
    schema,
    ajvOptions: {
        allErrors: true,
        verbose: true,
        code: {
            lines: true,
            source: true
        }
    }
});
export const ScadParameterEditor = observer(function ScadParameterEditor(props) {
    const { variables, className, setData } = props;
    return _jsx(SvelteJSONEditor, { onChange: (content, previousContent, status) => {
            console.log('onChange', content, previousContent, status);
            if (status.contentErrors) {
                console.error('contentErrors', status.contentErrors);
                if ('parseError' in status.contentErrors) {
                    return;
                }
            }
            if ('json' in content && content.json) {
                setData(content.json);
            }
            if ('text' in content && content.text) {
                try {
                    const json = JSON.parse(content.text);
                    setData(json);
                }
                catch (e) {
                    console.error(e);
                }
            }
            // setData(content.json)
        }, 
        // mode={Mode.tree}
        content: { json: variables }, validator: ScadParameterValidator });
});
