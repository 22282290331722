import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { ScadDocumentProvider, useScadDocument } from "../../stores/ScadDocumentDataFrame";
import React, { Suspense, useEffect } from "react";
import { FullPageCircularLoader } from "../../components/atoms/FullPageCircularLoader";
import { EditorPanelWithSTLViewer } from "../../components/scad/Editor/Editor";
import { EditorFSProvider } from "../../stores/fs";
import { ModelProvider, useModel } from "../../stores/model";
import { ScadParams } from "../../components/scad/ScadParams/ScadParams";
import { Button, Chip, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import css from './Model.module.scss';
import { SimpleScadControls } from "../../components/scad/ScadEditor/ScadEditor";
import { deepObserve } from "mobx-utils";
import { BuildVolume } from "../../components/atoms/BuildVolume/BuildVolume";
import { SideBySide } from "../../components/atoms/SideBySide/SideBySide";
import { Editor, EditorProvider } from "react-simple-wysiwyg";
const Controls = observer(function Controls() {
    const model = useModel();
    const doc = useScadDocument();
    return _jsxs("div", { className: css.controls, children: [_jsx(BuildVolume, {}), _jsx(Button, { className: css.exportButton, onClick: async () => {
                    if (!model.output || model.output.isPreview) {
                        await model.renderAsync({ now: true, isPreview: false });
                    }
                    await model.exportAsync(`${doc.sanitizedNameForFile}.stl`);
                    await doc.markRender();
                }, variant: "contained", color: "secondary", children: "Export STL" })] });
});
const ModelPanel = observer(function ModelPanel() {
    const doc = useScadDocument();
    const data = doc.read();
    const model = useModel();
    const [selectedPreset, setSelectedPreset] = React.useState(0);
    useEffect(() => {
        const disposer = deepObserve(model.params.vars, () => {
            // console.log('changed', model.params.vars)
            setSelectedPreset(-1);
        });
        return () => {
            disposer();
        };
    }, [setSelectedPreset]);
    return (_jsxs(_Fragment, { children: [_jsxs(FormControl, { variant: "filled", style: { marginBottom: '1rem' }, children: [_jsx(InputLabel, { id: "preset", children: "Presets" }), _jsxs(Select, { labelId: "preset", label: "Preset", onChange: e => {
                            const presetIndex = e.target.value;
                            if (presetIndex === -1) {
                                return;
                            }
                            const preset = data.presets[presetIndex].variables;
                            model.setPresets(preset);
                            setSelectedPreset(presetIndex);
                        }, value: selectedPreset, variant: "filled", children: [data.presets.map((p, i) => {
                                return _jsx(MenuItem, { value: i, children: p.name }, i);
                            }), _jsx(MenuItem, { value: -1, children: "Custom" })] })] }), _jsx(ScadParams, { className: css.params }), _jsx(Controls, {}), _jsx(SimpleScadControls, {})] }));
});
const ModelDescription = observer(function ModelDescription() {
    const doc = useScadDocument();
    const data = doc.read();
    return (_jsxs("div", { className: css.editor, children: [_jsx(Typography, { variant: "h4", className: css.title, children: data.name }), _jsxs("div", { className: css.details, children: [_jsxs(Typography, { children: ["By: @", data.uploader?.username ?? '-'] }), _jsx("div", { className: css.tagsContainer, children: data.tags.map((t, i) => _jsx(Chip, { label: t, size: "small", variant: "outlined", color: "secondary" }, i)) })] }), _jsx("div", { className: css.spacer }), _jsx(EditorProvider, { children: _jsx(Editor, { value: data.desc, disabled: true, className: css.editor }) })] }));
});
const Model = observer(function Model() {
    const doc = useScadDocument();
    const data = doc.read();
    return (_jsx(EditorFSProvider, { children: _jsx(ModelProvider, { arguments: [data.code], children: _jsx(SideBySide, { leftSide: _jsx(ModelDescription, {}), defaultSplit: Math.min(window.innerWidth / 3, 600), rightSide: _jsx(EditorPanelWithSTLViewer, { defaultSplit: 400, panelClassName: css.panel, children: _jsx(ModelPanel, {}) }) }) }) }));
});
export const ModelPage = observer(function ModelPage() {
    const { id } = useParams();
    return (_jsx(ScadDocumentProvider, { arguments: [id], children: _jsx(Suspense, { fallback: _jsx(FullPageCircularLoader, {}), children: _jsx(Model, {}) }) }));
});
