// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WMaHFApIE2GDtLISmyVb{display:flex;flex-direction:column;width:100%;height:100%}.WMaHFApIE2GDtLISmyVb .dpThy6LroxrRc5iXizuw{overflow-y:auto;overflow-x:hidden;flex:1}.WMaHFApIE2GDtLISmyVb .l892LBiFpoyGyLpAlnTw{margin:8px;margin-top:auto;flex:0;align-items:center;display:flex;flex-direction:row;flex-wrap:wrap}.WMaHFApIE2GDtLISmyVb .l892LBiFpoyGyLpAlnTw .ZrQSy7TacIxTweM0qoie{font-weight:600}.WMaHFApIE2GDtLISmyVb .l892LBiFpoyGyLpAlnTw>:last-child{margin-left:auto}.LknRYyM39xLIv7SVJsMY{overflow:auto}.LknRYyM39xLIv7SVJsMY .htb5f8wA0yVWcHzXTU1o{margin:8px}.LknRYyM39xLIv7SVJsMY .iKyK5JINdj5PLMLLPnyq{display:flex;flex-direction:column;margin:8px}.LknRYyM39xLIv7SVJsMY .iKyK5JINdj5PLMLLPnyq .IyD0DLhCDF6ldmZTsD5J>*{margin:4px}.LknRYyM39xLIv7SVJsMY .iKyK5JINdj5PLMLLPnyq .IyD0DLhCDF6ldmZTsD5J>*:last-child{margin-right:0}.LknRYyM39xLIv7SVJsMY .iKyK5JINdj5PLMLLPnyq .IyD0DLhCDF6ldmZTsD5J>*:first-child{margin-left:0}.LknRYyM39xLIv7SVJsMY .AWdFZVFqKjFHrgVJaiqc{height:24px}.LknRYyM39xLIv7SVJsMY .rsw-editor{border:none}`, "",{"version":3,"sources":["webpack://./src/pages/Model/Model.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,WAAA,CAEA,4CACE,eAAA,CACA,iBAAA,CACA,MAAA,CAGF,4CACE,UAAA,CACA,eAAA,CACA,MAAA,CACA,kBAAA,CAEA,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,kEACE,eAAA,CAGF,wDACE,gBAAA,CAKN,sBACE,aAAA,CAEA,4CACE,UAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,UAAA,CAGE,oEACE,UAAA,CAEA,+EACE,cAAA,CAEF,gFACE,aAAA,CAMR,4CACE,WAAA,CAGF,kCACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `WMaHFApIE2GDtLISmyVb`,
	"params": `dpThy6LroxrRc5iXizuw`,
	"controls": `l892LBiFpoyGyLpAlnTw`,
	"exportButton": `ZrQSy7TacIxTweM0qoie`,
	"editor": `LknRYyM39xLIv7SVJsMY`,
	"title": `htb5f8wA0yVWcHzXTU1o`,
	"details": `iKyK5JINdj5PLMLLPnyq`,
	"tagsContainer": `IyD0DLhCDF6ldmZTsD5J`,
	"spacer": `AWdFZVFqKjFHrgVJaiqc`
};
export default ___CSS_LOADER_EXPORT___;
