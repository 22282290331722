// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QxzrU4qQVONao1aKQPMi{display:flex;flex-flow:row;flex-wrap:nowrap;align-items:end}.QxzrU4qQVONao1aKQPMi .lpIlZdqBidZ6TFzpR8Hy{width:60px}.QxzrU4qQVONao1aKQPMi svg{margin:4px}`, "",{"version":3,"sources":["webpack://./src/components/atoms/BuildVolume/BuildVolume.module.scss"],"names":[],"mappings":"AAAA,sBAEE,YAAA,CACA,aAAA,CACA,gBAAA,CACA,eAAA,CAEA,4CACE,UAAA,CAGF,0BACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buildVolume": `QxzrU4qQVONao1aKQPMi`,
	"input": `lpIlZdqBidZ6TFzpR8Hy`
};
export default ___CSS_LOADER_EXPORT___;
