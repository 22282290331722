import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loader from '@monaco-editor/loader';
import openscadLanguage from "../../../openscad/language/openscad-language";
import { buildOpenSCADCompletionItemProvider } from "../../../openscad/language/openscad-completions";
import { observer } from "mobx-react-lite";
import { useEditorFS } from "../../../stores/fs";
import { useModel } from "../../../stores/model";
import { forwardRef, useEffect, useRef, useState } from "react";
import { zipArchives } from "../../../openscad/fs/zip-archives";
import MonacoEditor from '@monaco-editor/react';
import openscadEditorOptions from "../../../openscad/language/openscad-editor-options";
import { Button, LinearProgress, Paper, Popover, Typography } from "@mui/material";
import { useElementSize } from "../../../util/hooks";
const isMonacoSupported = (() => {
    const ua = window.navigator.userAgent;
    const iosWk = ua.match(/iPad|iPhone/i) && ua.match(/WebKit/i);
    return !iosWk;
})();
const monaco = await loader.init();
monaco.languages.register({
    id: 'openscad',
    extensions: ['.scad'],
    mimetypes: ["text/openscad"],
});
const { conf, language } = openscadLanguage;
monaco.languages.setLanguageConfiguration('openscad', conf);
monaco.languages.setMonarchTokensProvider('openscad', language);
export const SimpleScadControls = observer(forwardRef((props, ref) => {
    const model = useModel();
    const logsButtonRef = useRef(null);
    const [logsOpen, setLogsOpen] = useState(false);
    const { showRenderButton = true } = props;
    return (_jsxs("div", { ref: ref, children: [_jsx(LinearProgress, { variant: model.rendering || model.previewing ? 'indeterminate' : 'determinate', color: model.previewing ? 'warning' : model.rendering ? 'info' : model.error ? 'error' : 'success', value: 100 }), _jsxs(Paper, { style: { display: 'flex', flexFlow: 'row', alignItems: 'flex-end' }, children: [_jsx(Button, { size: "small", variant: "contained", style: { width: 24, height: 24, minWidth: 'unset', marginRight: 8 }, ref: logsButtonRef, onClick: () => setLogsOpen(s => !s), children: '>. ' }), _jsxs(Typography, { variant: "body2", children: [model.previewing && `Previewing... ${model.logStream[model.logStream.length - 1]?.[1] ?? ''}`, model.rendering && `Rendering... ${model.logStream[model.logStream.length - 1]?.[1] ?? ''}`, model.error && `Error: ${model.error}`, !model.previewing && !model.rendering && !model.error && 'Ready'] }), showRenderButton && (_jsx(Button, { size: "small", variant: "contained", style: { width: 64, height: 24, minWidth: 'unset', marginLeft: 'auto' }, onClick: () => model.renderAsync({ now: true, isPreview: false }), children: 'Render' }))] }), _jsx(Popover, { open: logsOpen, onClose: () => setLogsOpen(false), anchorEl: logsButtonRef.current, transformOrigin: { vertical: 'bottom', horizontal: 'left' }, elevation: 5, children: _jsxs("div", { style: { padding: 8, background: "#0e0e0e" }, children: [_jsx(Typography, { variant: "body2", children: "Logs:" }), _jsx("pre", { style: { overflow: 'auto', maxHeight: 300, minWidth: 350, maxWidth: '80vw', minHeight: 100 }, children: model.logStream.map(([pipe, message], i) => (_jsxs("span", { style: { color: pipe === 'stderr' ? 'yellow' : 'white' }, children: [message, '\n'] }, i))) })] }) })] }));
}));
export const ScadEditor = observer((props) => {
    const fs = useEditorFS();
    const model = useModel();
    const [editor, setEditor] = useState(null);
    const logsButtonRef = useRef(null);
    const [logsOpen, setLogsOpen] = useState(false);
    const [otherContentRef, { width: otherWidth, height: otherHeight }] = useElementSize();
    useEffect(() => {
        if (fs.isInitialized) {
            buildOpenSCADCompletionItemProvider(fs, '/', zipArchives).then(provider => {
                monaco.languages.registerCompletionItemProvider('openscad', provider);
            });
        }
    }, [fs.isInitialized]);
    useEffect(() => {
        if (editor) {
            const checkerRun = model.lastCheckerRun;
            const editorModel = editor.getModel();
            if (editorModel) {
                if (checkerRun && monaco) {
                    monaco.editor.setModelMarkers(editorModel, 'openscad', checkerRun.markers);
                }
            }
        }
    }, [editor]);
    const onMount = (editor) => {
        editor.addAction({
            id: "openscad-render",
            label: "Render OpenSCAD",
            run: () => model.renderAsync({ isPreview: false, now: true })
        });
        editor.addAction({
            id: "openscad-preview",
            label: "Preview OpenSCAD",
            run: () => model.renderAsync({ isPreview: true, now: true })
        });
        editor.addAction({
            id: "openscad-save-do-nothing",
            label: "Save (disabled)",
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS],
            run: () => {
            }
        });
        // editor.addAction({
        //   id: "openscad-save-project",
        //   label: "Save OpenSCAD project",
        //   keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyS],
        //   run: () => model.saveProject()
        // });
        setEditor(editor);
    };
    return _jsxs("div", { className: props.className, children: [_jsx(MonacoEditor
            // className={}
            , { 
                // className={}
                theme: "vs-dark", defaultLanguage: "openscad", path: model.params.activePath, value: model.source, onChange: s => model.setSource(s ?? ''), onMount: onMount, options: {
                    ...openscadEditorOptions,
                    fontSize: 16,
                    lineNumbers: 'on',
                    // readOnly: !isFileWritable(state.params.sourcePath)
                }, width: props.width, height: typeof props.height === 'number' ? props.height - otherHeight : props.height }), _jsx(SimpleScadControls, { ref: otherContentRef })] });
});
