var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createEditorFS } from "../openscad/fs/filesystem";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { createContext } from "../util/context";
export let fsSingleton = null;
class EditorFS {
    get isInitialized() {
        return this.fs !== null;
    }
    constructor() {
        this.fs = null;
        makeObservable(this);
        // @ts-ignore
        window.fs = this;
    }
    async init() {
        const fs = fsSingleton || await createEditorFS({ prefix: '/', allowPersistence: true });
        fsSingleton = fs;
        runInAction(() => {
            this.fs = fs;
        });
    }
    lstatSync(path) {
        return this.fs.lstatSync(path);
    }
    readFileSync(path) {
        return this.fs.readFileSync(path);
    }
    readdir(path, cb) {
        this.fs.readdir(path, cb);
    }
    readdirSync(path) {
        return this.fs.readdirSync(path);
    }
    symlink(target, source) {
        this.fs.symlink(target, source);
    }
    writeFile(path, content) {
        this.fs.writeFile(path, content);
    }
}
__decorate([
    observable
], EditorFS.prototype, "fs", void 0);
__decorate([
    computed
], EditorFS.prototype, "isInitialized", null);
export const { useStore: useEditorFS, Provider: EditorFSProvider } = createContext(EditorFS, {
    init: s => s.init()
});
