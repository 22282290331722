export function mapObject(o, f, ifPred) {
    const ret = [];
    for (const key of Object.keys(o)) {
        if (ifPred && !ifPred(key)) {
            continue;
        }
        ret.push(f(key, o[key]));
    }
    return ret;
}
export function AbortablePromise(f) {
    let kill;
    const promise = new Promise((res, rej) => {
        kill = f(res, rej);
    });
    return Object.assign(promise, { kill: kill });
}
// todo this looks like a debounce function with extra steps to kill previous executions
//      should be refactored to use debounce
// <T extends any[]>(...args: T)
export function turnIntoDelayableExecution(delay, job) {
    let pendingId;
    let runningJobKillSignal;
    return (...args) => async ({ now, callback }) => {
        const doExecute = async () => {
            if (runningJobKillSignal) {
                runningJobKillSignal();
                runningJobKillSignal = null;
            }
            const abortablePromise = job(...args);
            runningJobKillSignal = abortablePromise.kill;
            try {
                callback(await abortablePromise);
            }
            catch (e) {
                callback(undefined, e);
            }
            finally {
                runningJobKillSignal = null;
            }
        };
        if (pendingId) {
            clearTimeout(pendingId);
            pendingId = null;
        }
        if (now) {
            doExecute();
        }
        else {
            pendingId = window.setTimeout(doExecute, delay);
        }
    };
}
// todo: this just just a bandaid for the previous function
export function promisifyDelayableExecution(fn) {
    return (arg) => {
        return new Promise((resolve, reject) => {
            fn({ now: arg.now, callback: (result, err) => {
                    if (err) {
                        return reject(err);
                    }
                    if (!result) {
                        return reject('Invalid state: No result and no error');
                    }
                    return resolve(result);
                } });
        });
    };
}
export function validateStringEnum(s, values, orElse = s => { throw new Error(`Unexpected value: ${s} (valid values: ${values.join(', ')})`); }) {
    return values.indexOf(s) < 0 ? orElse(s) : s;
}
export const validateBoolean = (s, orElse = () => false) => typeof s === 'boolean' ? s : orElse();
export const validateString = (s, orElse = () => '') => s != null && typeof s === 'string' ? s : orElse();
export const validateArray = (a, validateElement, orElse = () => []) => {
    if (!(a instanceof Array))
        return orElse();
    return a.map(validateElement);
};
export function formatBytes(n) {
    if (n < 1024) {
        return `${Math.floor(n)} bytes`;
    }
    n /= 1024;
    if (n < 1024) {
        return `${Math.floor(n * 10) / 10} kB`;
    }
    n /= 1024;
    return `${Math.floor(n * 10) / 10} MB`;
}
export function formatMillis(n) {
    if (n < 1000)
        return `${Math.floor(n)}ms`;
    return `${Math.floor(n / 100) / 10}sec`;
}
// https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
export function registerCustomAppHeightCSSProperty() {
    const updateAppHeight = () => {
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', updateAppHeight);
    updateAppHeight();
}
// In PWA mode, persist files in LocalStorage instead of the hash fragment.
export const isInStandaloneMode = 
// true
Boolean(('standalone' in window.navigator) && (window.navigator.standalone));
export function downloadUrl(url, filename) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
}
export async function fetchSource({ content, path, url }) {
    if (content) {
        return content;
    }
    else if (url) {
        if (path.endsWith('.scad') || path.endsWith('.json')) {
            content = await (await fetch(url)).text();
            return content;
        }
        else {
            // Fetch bytes
            const response = await fetch(url);
            const buffer = await response.arrayBuffer();
            const data = new Uint8Array(buffer);
            return data;
        }
    }
    else {
        throw new Error('Invalid source: ' + JSON.stringify({ path, content, url }));
    }
}
